import React, {useEffect, useState, useRef} from 'react';
import {
  Link,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import {useAuthRequest} from '../auth.js';
import moment from 'moment';
import {PlanBadge, Paginator, Select} from '../dsb.js';
import Layout from '../components/layout';
import {  MagnifyingGlassIcon, CheckIcon, XMarkIcon} from '@heroicons/react/24/solid'

export default function Stores() {

	const {authFetch} = useAuthRequest();

	const navigate = useNavigate();

	const planRef = useRef();

	const queryRef = useRef();

	const [searchParams, setSearchParams] = useSearchParams();

	const [planCode, setPlanCode] = useState('');

	const [stores, setStores] = useState(null);

	const loadStores = async() => {

		const planCode = searchParams.has('plan_code')?searchParams.get('plan_code'):'all';
		const active = searchParams.has('active')?searchParams.get('active'):'2';
		const page = searchParams.has('page')?searchParams.get('page'):'1';
		const q = searchParams.has('q')&&searchParams.get('q').length>0?`&q=${searchParams.get('q')}`:'';

		const response = await authFetch(`/api/admin/stores?plan_code=${planCode}&active=${active}&page=${page}${q}`);

		const { stores } = response;

		if (stores) {
			setStores(stores);
			window.scrollTo(0,0);
		}

	}

	const submitSearch = (e) => {
		e.preventDefault();

		const q = queryRef.current.value;

		//alert(q);

		const url = `/stores?${planCode.length>0?`plan_code=${planCode}&`:''}${q.length>0?`q=${q}&`:''}`;

		navigate(url);

		return false;

	}

	const changePage = (page) => {
		const planCode = searchParams.has('plan_code')?searchParams.get('plan_code'):'all';
		const active = searchParams.has('active')?searchParams.get('active'):'2';
		navigate(`/stores?plan_code=${planCode}&active=${active}&page=${page}`);
	}

	useEffect(() => {
		loadStores();
	}, [searchParams]);

	return <Layout current="stores">
			<>
				
				<h3 className="text-white text-2xl md:text-4xl font-bold pt-4 mb-4">Stores</h3>
				<div className="mb-4">
					<form onSubmit={submitSearch}>
						<div className="flex shadow-lg mb-4">
							<input ref={queryRef} type="text" className="text-sm bg-white px-4 py-3 rounded-l-md w-full block" placeholder="Search by email, store name, shopify domain, ID"/>
							<button className="bg-indigo-600 font-bold text-sm px-6 py-3 rounded-r-md"><MagnifyingGlassIcon className="text-white w-5 h-5"/></button>
						</div>
						<div className="md:flex space-x-4">
							<div>
							<Select className="block w-full md:w-auto" onSelect={(e) => setPlanCode(e.target.value)} options={[
									{label: 'All plans', value: ''},
									{label: 'None', value: 'none'},
									{label: 'Basic', value: 'basic'},
									{label: 'Advanced', value: 'advanced'},
									{label: 'Pro', value: 'pro'},
								]} />
							</div>
						</div>
					</form>
				</div>
				<div className="bg-gray-800 rounded-md overflow-x-auto">
					<table className="w-full">
					<thead className="text-left border-b border-slate-700 text-slate-300 text-xs uppercase">
						<tr>
							<th className=" px-4 py-3">ID</th>
							<th className="truncate px-4 py-3">Store Name</th>
							<th className="px-4 py-3">Email</th>
							<th>Domain</th>
							<th>Plan</th>
							<th className="px-4 py-3 min-w-[120px] md:w-auto">Active</th>
							<th className="px-4 py-3 min-w-[120px] md:w-auto">Joined</th>
							<th className="px-4 py-3 min-w-[120px] md:w-auto">Last Login</th>
							
						</tr>
					</thead>
					<tbody className="divide-y divide-slate-700">
					{
						stores!=null&&stores.data.map(store => <tr key={`store-${store.id}`} >
								<td className=" px-4 py-3"><Link className="text-indigo-300 text-sm" to={`/stores/${store.id}`}>{store.id}</Link></td>
								<td className="truncate px-4 py-3"><Link className="text-indigo-300 text-sm" to={`/stores/${store.id}`}>{store.store_name}</Link></td>
								<td className="truncate max-w-xs px-4 py-3"><a href={`mailto:${store.email}`} className="text-indigo-300 text-sm">{store.email}</a></td>
								<td><a href={`https://${store.shopify_domain}.myshopify.com`} className="text-indigo-300 text-sm">{store.shopify_domain}</a></td>
								<td><PlanBadge plan={store.plan_code}/></td>
								<td className="px-4 py-3 min-w-[120px] md:w-auto">{store.active?<CheckIcon className="w-5 h-5 text-lime-500"/>:<XMarkIcon className="w-5 h-5 text-red-500"/>}</td>
								<td className="px-4 py-3 min-w-[120px] md:w-auto"><span className="text-gray-400 text-sm">{moment(store.created_at).fromNow()}</span></td>
								<td className="px-4 py-3  md:w-auto"><span className="text-gray-400 text-sm">{moment(store.last_login_at).fromNow()}</span></td>
								
							</tr>)
					}
					{
						stores!=null&&stores.data.length==0&&<tr>
							<td colspan={8}>
								<div className="py-32 px-4 text-center">
									<p className="text-sm text-white">No stores found according to your search parameters.</p>
								</div>	
							</td>
						</tr>
					}
					</tbody>
					</table>
					

				</div>
				{stores!=null&&<div className="px-4 py-4 border-t border-slate-700">
						<Paginator data={stores} changePage={changePage}/>
					</div>}
				
			</>
	</Layout>;
}