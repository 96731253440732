import React, {useEffect, useState} from 'react';
import {
  Link,
  useParams,
  useRouteMatch,
  useSearchParams,
  useNavigate
} from "react-router-dom";
import {MenuButton, Paginator, Select } from '../dsb';
import {useAuthRequest} from '../auth.js';
import {useCookies} from 'react-cookie';
import Layout from '../components/layout';
import numeral from 'numeral';
import moment from 'moment';
import { ArrowUpIcon, ArrowDownIcon} from '@heroicons/react/24/solid'

const links = [
	{name: 'Pending', id: 'pending'},
	{name: 'In Progress', id: 'in_progress'},
	{name: 'Completed', id: 'complete'},
	{name: 'Archived', id: 'archive'}
];

export default function CustomBrandingRequests({match, history}) {

	const {authFetch} = useAuthRequest();

	const [searchParams, setSearchParams] = useSearchParams();

	const navigate = useNavigate();

	const [requests, setRequests] = useState(null);

	const current = searchParams.has('status')?searchParams.get('status'):'pending';

	const page = searchParams.has('page')?searchParams.get('page'):'1';

	const loadRequests = async() => {
		const response = await authFetch(`/api/admin/custom-branding-requests?page=${page}&status=${current}`);

		const { requests, error} = response;

		if (requests) {
			setRequests(requests);
		}
	}

	useEffect(() => {
		loadRequests();
	}, [searchParams]);

	return <Layout current={'branding'}>
		<>
			<h3 className="text-white text-2xl md:text-4xl font-bold pt-4 mb-4 truncate">Custom Branding Requests</h3>
			<div className="hidden md:block">
				<ul className="px-2 py-2">
					{
						links.map(link => <li key={`link-${link.id}`} className="inline-block"><Link to={`/custom-branding-requests?status=${link.id}`} className={`${(current==link.id)&&'bg-slate-600'} hover:bg-gray-600 rounded-md text-white px-4 py-3 flex space-x-3 items-center`}>
									<span className="text-sm text-gray-200">{link.name}</span>
								</Link>
							</li>)
					}
				</ul>
			</div>
			<div className="md:hidden mb-4">
					
				<Select className="md:w-auto md:block-inline block w-full" options={links.map(link => ({label: link.name, value: link.id}))} value={searchParams.get('status')} onSelect={(e) => navigate(`/custom-branding-requests?status=${e.target.value}`)}/>
		
			</div>
			{
				requests!=null&&<><div className="bg-slate-800 rounded-md">
						<div className="px-4 py-3 border-b border-slate-700">
						<h3 className="text-slate-200 font-bold text-xl truncate">Custom Branding Requests</h3>
						</div>
						<div className=" overflow-auto">
					<table className="w-[600px] md:w-full">
					<thead className="text-left border-b border-slate-700 text-slate-300 text-xs uppercase">
						<tr>
							<th className="px-4 py-3">ID</th>
							<th className="px-4 py-3">Store</th>
							<th className="px-4 py-3">Submitted</th>
							<th className="px-4 py-3 text-right">Action</th>
						</tr>
					</thead>
					<tbody className="divide-y divide-slate-700 w-full  text-sm text-gray-200 border-b border-slate-700">
					{
						requests.data.map(request => <tr key={`request-${request.id}`}>
								<td className="px-4 py-3"><Link to={`/custom-branding-requests/${request.id}`} className="text-cyan-400">{request.id}</Link></td>
								<td className="px-4 py-3"><Link to={`/stores/${request.user_id}`} className="text-cyan-400">{request.user.store_name}</Link></td>
								<td className="px-4 py-3"><span>{moment(request.created_at).fromNow()}</span></td>
								<td className="px-4 py-3 text-right"><Link to={`/custom-branding-requests/${request.id}`} className="text-cyan-400">View</Link></td>
							</tr>)
					}
					</tbody>
					</table>
					</div>

					

				</div>
				<div className=" py-3">
					<Paginator data={requests} changePage={(newPage) => navigate(`/custom-branding-requests?status=${current}&page=${newPage}`)}/>
					</div>
				
				</>
			}
		</>
	</Layout>
}