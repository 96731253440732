import React, {useEffect, useState} from 'react';
import {
  Link,
  useParams,
  useRouteMatch,
  useNavigate
} from "react-router-dom";
import { Modal, Title, Label, Toast} from '../dsb.js';
import {useCookies} from 'react-cookie';

export default function Login() {

	const navigate = useNavigate();

	const [email, setEmail] = useState('');

	const [password, setPassword] = useState('');

	const [remember, setRemember] = useState(false);

	const [forgotModal, setForgotModal] = useState(false);

	const [message, setMessage] = useState('');

	const [toastMessage, setToastMessage] = useState(false);

	const [error, setError] = useState('');

	const [disabled, setDisabled] = useState(false);


  	const [cookies, setCookie, removeCookie] = useCookies(['_token']);

  	const sendResetInstructions = async() => {
  		const formData = new FormData();

  		setDisabled(true);

  		formData.append('email', email);

  		const url = process.env.NODE_ENV=='production'?`https://dropshipbeauty.app/api/admin/reset`:`https://dropshipbeauty.xyz/api/admin/reset`;


  		const response = await fetch(url, {
  			method: 'POST',
  			body: formData
  		}).then(resp => {

	    	return resp.json();

	    });

	    const {error, message} = response;

	    if (error) {
	    	setToastMessage(error);
	    }

	    if (message) {
	    	setToastMessage(message);
	    	setForgotModal(false);
	    }

	    setDisabled(false);
  	}

	const signIn = async(e) => {
	    e.preventDefault();

	    const form = new FormData();

	    form.append('email', email);
	    form.append('password', password);
	    if (remember) {
	      form.append('remember_me', remember);
	    }

	    setError(false);
	    setDisabled(true);

	    const url = process.env.NODE_ENV=='production'?`https://dropshipbeauty.app/api/admin/login`:`https://dropshipbeauty.xyz/api/admin/login`;

	    const response = await fetch(url, {
	      method: 'POST',
	      body: form
	    }).then(resp => {

	    	if (resp.status == 401) {
	    		return {error: 'Your credentials are incorrect. Please double check your email / password, and try again.'};
	    	}

	    	return resp.json();

	    });

	    const {error, jwt} = response;

	    if (error) {
	      setError(error);

	      setDisabled(false);
	    }

	    if (jwt) {

	    	console.log(jwt);

	      setMessage(message);
	      let options = {
	        path: '/'
	      };

	      if (remember == true) {
	        var date = new Date();
	        date.setDate(date.getDate()+7);
	        options['expires'] = date;
	      }

	      setCookie('_token', jwt, options);

	      setTimeout(() => {
	      	navigate('/dashboard');
	      }, 300);
	      
    }

    return false;
  }

	return <main className="bg-gray-800 h-screen">
		
		<div className="container mx-auto py-6 h-full px-6">
			<div className="md:grid grid-cols-2 h-full">
				<div className="flex items-center justify-center relative h-full">
					<Link to="/"><img src="https://res.cloudinary.com/wigmarket/image/upload/v1680273284/dropship-beauty-logo_200x_2x_h9r4bf_oeynnl_nda6ep.png" className="w-32 absolute top-0 left-0"/></Link>
					<div className="w-full flex items-center justify-center">
						<form onSubmit={signIn} className="w-96">
						<div className="w-full space-y-5">
							<div>
								<label className="block font-bold text-slate-100 text-xs uppercase">Email</label>
								<input type="email" name="email" className="text-white border-b border-slate-400 bg-transparent block w-full px-2 py-2" placeholder="you@email.com" value={email} onChange={(e) => setEmail(e.target.value)}/>
							</div>
							<div>
								<label className="block font-bold text-slate-100 text-xs uppercase">Password</label>
								<input type="password" name="password" className="text-white border-b border-slate-400 bg-transparent block w-full px-2 py-2" value={password} onChange={(e) => setPassword(e.target.value)}/>
							</div>
							<div className="space-x-1 flex items-center justify-between">
								<div className="space-x-1 flex items-center">
									<input type="checkbox" name="remember" className="bg-white w-4 h-4 border border-slate-400" id="remember" checked={remember} onChange={(e) => setRemember(e.target.checked)}/>
									<label htmlFor="remember" className="text-slate-400 text-sm ">Remember me</label>
								</div>
								<div>
									<button type="button" onClick={() => setForgotModal(true)} className="text-slate-400 text-sm ">Forgot Password?</button>
								</div>
							</div>
							{
								error!=''&&<div className="">
									<span className="text-red-400 text-sm">{error}</span>
								</div>
							}
							{
								message!=''&&<div className="">
									<span className="text-lime-400 text-sm">{message}</span>
								</div>
							}
							<div>
								<button disabled={disabled} className="disabled:opacity-50 shadow-lg bg-indigo-600 text-white rounded-md px-4 py-3 text-center w-full block font-bold text-sm">Sign In</button>
							</div>
						</div>
						</form>
					</div>
				</div>
				<div className="h-full hidden md:block">
					<div className="bg-cover bg-center rounded-lg h-full" style={{backgroundImage: "url('https://images.unsplash.com/photo-1583147610149-78ac5cb5a303?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3087&q=80')"}}>
						&nbsp;
					</div>
				</div>
			</div>
		</div>
		<Modal title="Forgot your password?" open={forgotModal} close={() => setForgotModal(false)} primary={{label: 'Send Instructions', action: () => sendResetInstructions(), disabled: false}} secondary={{label: 'Close', action:() => setForgotModal(false)}}>
			<p className="text-slate-400 mb-3 text-sm">Enter your email address below to trigger the password reset process.</p>
			<Label>Email Address</Label>
			<input placeholder="admin@dropshipbeauty.app" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} className="bg-slate-700 text-slate-200 border-slate-400 w-full block px-4 py-3 rounded-md"/>
		</Modal>
		<Toast message={toastMessage} onReset={() => setToastMessage(false)}/>
	</main>
}