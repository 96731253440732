import React, {useState, useEffect} from 'react';
import {
  Link
} from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'

export function Title({children}) {
	return <h3 className="text-white text-4xl font-bold mt-4 mb-4">{children}</h3>
}

export function Paginator({data, changePage}) {

	const prevPage = () => {
		if (data.current_page <= 1) {
			return false;
		}

		const page = data.current_page-1;

		changePage(page);
	}

	const nextPage = () => {
		if (data.current_page >= data.last_page) {
			return false;
		}

		const page = data.current_page+1;

		changePage(page);
	}

	const pages = [];

	const start = (data.current_page<=3)?1:data.current_page-2;

	for(var i = start; i <= data.last_page && i <= (data.current_page+6); i++) {
		const p = i;
		pages.push(p)
	}

	const includeFirst = (start!=1);

	const includeLast = (data.current_page < data.last_page-6);

	return <><div className="flex items-center hidden md:flex">
		<button onClick={() => prevPage()} className="text-sm border px-4 border-slate-600 py-2 h-10 rounded-l-md cursor-pointer text-slate-400"><ChevronLeftIcon className="w-4 h-4 block"/></button>
		{
			includeFirst && <>
				<button onClick={() => changePage(1)} key={`page-1`} className={`text-slate-400 border-slate-600 h-10 text-sm border border-l-0 px-4 py-2 cursor-pointer`}>1</button>
				<span className="h-10 text-slate-400 border-slate-600 text-sm border border-l-0 px-4 py-2">...</span>
			</>
		}
		{
			pages.map(page => <button onClick={() => changePage(page)} key={`page-${page}`} className={`h-10 text-sm border border-l-0 px-4 py-2 cursor-pointer ${data.current_page==page?'bg-slate-900 text-white border-slate-600':'border-slate-600 text-slate-400'}`}>{page}</button>)
		}
		{
			includeLast && <>
				<span className="text-sm text-slate-400 border-slate-600 border border-l-0 px-4 py-2 h-10 ">...</span>
				<button onClick={() => changePage(data.last_page)} key={`page-${data.last_page}`} className={`h-10 text-sm border border-slate-600 text-slate-400 border-l-0 px-4 py-2 cursor-pointer`}>{data.last_page}</button>
				
			</>
		}
		<button onClick={() => nextPage()} className="h-10 text-sm border px-4 py-2 border-slate-600 border-l-0 rounded-r-md cursor-pointer text-slate-400"><ChevronRightIcon className="w-4 h-4 block"/></button>
	</div>
	<div className="flex items-center block md:hidden">
		<button onClick={() => prevPage()} className="text-sm border px-4 border-slate-600 py-2 h-10 rounded-l-md cursor-pointer text-slate-400"><ChevronLeftIcon className="w-4 h-4 block"/></button>
		
		<span className="flex-1 text-center border-t border-r border-b px-4 py-2 text-sm border-slate-600 h-10 text-slate-400">Page {data.current_page} of {data.last_page}</span>
		
		
		<button onClick={() => nextPage()} className="h-10 text-sm border px-4 py-2 border-slate-600 border-l-0 rounded-r-md cursor-pointer text-slate-400"><ChevronRightIcon className="w-4 h-4 block"/></button>
	</div>

	</>
}

export function PlanBadge({plan}) {
	switch(plan) {
		case 'none':
			return <span className="bg-slate-600 text-xs font-bold text-white px-2 py-1 rounded-lg">None</span>
		case 'basic':
			return <span className="bg-orange-600 text-xs font-bold text-white px-2 py-1 rounded-lg">Basic</span>
		case 'advanced':
			return <span className="bg-cyan-600 text-xs font-bold text-white px-2 py-1 rounded-lg">Advanced</span>
		case 'pro':
			return <span className="bg-lime-600 text-xs font-bold text-white px-2 py-1 rounded-lg">Pro</span>
		case 'lifetime':
			return <span className="bg-lime-600 text-xs font-bold text-white px-2 py-1 rounded-lg">Lifetime</span>
		default:
			return <span className="">{plan}</span>
	}
}

export function Label({children}) {
	return <label className="text-sm font-bold text-slate-200 block">{children}</label>
}

export function SetupStatus({status})  {
	switch(status) {
		case 'pending':
			return <span className="bg-slate-600 text-xs font-bold text-white px-2 py-1 rounded-lg">Pending</span>
		case 'in_progress':
			return <span className="bg-cyan-600 text-xs font-bold text-white px-2 py-1 rounded-lg">In Progress</span>
		case 'complete':
			return <span className="bg-lime-600 text-xs font-bold text-white px-2 py-1 rounded-lg">Completed</span>
		case 'cancelled':
			return <span className="bg-rose-600 text-xs font-bold text-white px-2 py-1 rounded-lg">Cancelled</span>
		case 'archived':
			return <span className="bg-gray-600 text-xs font-bold text-white px-2 py-1 rounded-lg">Archived</span>
		default:
			return <span className="">{status}</span>
	}
}

export function BrandingStatus({status})  {
	switch(status) {
		case 'pending':
			return <span className="bg-slate-600 text-xs font-bold text-white px-2 py-1 rounded-lg">Pending</span>
		case 'in_progress':
			return <span className="bg-cyan-600 text-xs font-bold text-white px-2 py-1 rounded-lg">In Progress</span>
		case 'complete':
			return <span className="bg-lime-600 text-xs font-bold text-white px-2 py-1 rounded-lg">Completed</span>
		case 'cancelled':
			return <span className="bg-rose-600 text-xs font-bold text-white px-2 py-1 rounded-lg">Cancelled</span>
		case 'archived':
			return <span className="bg-gray-600 text-xs font-bold text-white px-2 py-1 rounded-lg">Archived</span>
		default:
			return <span className="">{status}</span>
	}
}

export function Select({options=[], onSelect, ref, value, defaultValue, className=''}) {
	const props = {};
	if (value) {
		props['value'] = value;
	}
	if (defaultValue) {
		props['defaultValue'] = defaultValue;
	}
	if (ref) {
		props['ref'] = ref;
	}
	return <select {...props} onChange={onSelect} className={`${className} bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}>
		{
			options.map(option => <option value={option.value} key={`option-${option.value}`}>{option.label}</option>)
		}
	</select>
}

export function Card({padding=true, title, children, action=null}) {
	return <div className="bg-slate-800 rounded-md divide-y divide-slate-700">
	<h4 className="text-white text-lg font-bold px-4  py-2">{title}</h4>
	<div className={padding&&'px-4 py-3'}>
		{children}
	</div>
	{action!=null&&<div className="px-4 py-3">
		<Button onClick={action.action}>{action.label}</Button>
	</div>}

	</div>
}

export function Button({onClick, children, disabled=false, primary=true}) {
	return <button disabled={disabled} onClick={onClick} className={`disabled:opacity-50 ${primary?'hover:bg-indigo-700 bg-indigo-600':'bg-slate-700 hover:bg-slate-600'} text-sm text-white text-center font-bold block w-full text-sm py-3 px-4 rounded-md`}>{children}</button>
}

export function variantToString(variant) {
	const string = `${variant.option_1&&variant.option_1}${variant.option_2!=null?` &middot; ${variant.option_2}`:''}${variant.option_3!=null?` &middot; ${variant.option_3}`:''}`;

	return string;
}


export function Toast({message, duration=2000, onReset}) {

	const [showToast, setShowToast] = useState(false);

	const close = () => {
		setShowToast(false);
		onReset();
	}

	useEffect(() => {
		if (message != false) {
			setShowToast(true);

			setTimeout(() => {
				setShowToast(false);
				onReset();
			}, duration);
		}
	}, [message]);

	if (showToast == false) {
		return null;
	}

	return <div className="fixed z-50 w-full bottom-0 left-0 flex justify-center h-[20vh] items-center">
		<div className="flex items-center bg-slate-200 rounded-md px-4 sm:px-6 py-2 text-slate-900 text-sm font-bold shadow-xl border-slate-400">
			{message}
			<button className="ml-2 text-xl hover:bg-white hover:text-black rounded-lg aspect-square block w-5 h-5 flex items-center justify-center" onClick={() => close()}>&times;</button>
		</div>
	</div>
}

export function OrderStatus({order}) {
	if (order.cancelled_at != null) 
			return <span className="bg-rose-600 w-24 inline-block text-center  text-xs font-bold text-white px-2 py-1 rounded-lg">Cancelled</span>	
	if (order.fulfilled_at != null)
		return <span className="bg-orange-600 w-24 inline-block text-center  text-xs font-bold text-white px-2 py-1 rounded-lg">Shipped</span>
	if (order.refund_id != null) 
		return <span className="bg-rose-500 w-24 inline-block text-center  text-xs font-bold text-white px-2 py-1 rounded-lg">Refunded</span>
	if (order.paid_at != null)
			return <span className="bg-lime-600 w-24  inline-block text-center  text-xs font-bold text-white px-2 py-1 rounded-lg">Paid</span>
	if (order.paid_at == null)
			return <span className="bg-slate-600 w-24 inline-block text-center text-xs font-bold text-white px-2 py-1 rounded-lg">Unpaid</span>


	
}

export function Modal({title, primary, secondary, children, close, open}) {
	return <div className={`${open?'':'hidden'} fixed bg-black/50 top-0 left-0 w-screen h-screen z-50 flex items-center justify-center`}>
		<div className="rounded-lg bg-slate-800 w-full max-w-xl divide-y divide-slate-600 shadow-xl">
			<div className="px-4 py-2 flex items-center justify-between">
				<h3 className="text-lg font-bold text-slate-200">{title}</h3>
				<button onClick={() => close()} className="text-slate-200 text-3xl">&times;</button>
			</div>
			<div className="px-4 py-4 text-sm">
			{children}
			</div>
			<div className="px-4 py-4 text-sm">
				<div className="grid grid-cols-2 gap-2">
					{
						secondary&&<button onClick={() => secondary.action()} className="hover:bg-slate-600 bg-slate-700 text-slate-200 px-4 py-3 rounded-md font-bold">{secondary.label}</button>
					}
					{
						primary&&<button disabled={primary.disabled} onClick={() => primary.action()} className="hover:bg-indigo-500 disabled:opacity-50 bg-indigo-600 text-white px-4 py-3 rounded-md font-bold">{primary.label}</button>
					}
				</div>
			</div>
		</div>
	</div>
}

export function MenuButton({label, items, className='', full=false}) {

	const [open, setOpen] = useState(false);

	const toggleMenu = () => {
		setOpen(!open);
	}

	const buttonClassName = 'hover:bg-slate-600 disabled:text-gray-400 text-slate-200 text-sm px-4 py-2 text-left block w-full';

	return <><div className={`${className} relative`}>
		<button className={`${open?'bg-slate-800':'bg-slate-600'} ${full?'w-full':'w-auto'} px-4 py-2 text-sm border border-slate-600  shadow rounded-md text-slate-200`} onClick={() => toggleMenu()}>{label}</button>
		{open&&<div className="absolute mt-1 z-40 w-64 right-0 shadow-lg rounded-lg bg-slate-800 border border-slate-600">
			<ul className="divide-y divide-slate-600">
				{
					items.map((item, i) => <li key={`menu-${i}`} className="block w-full">
							{item.url?<Link className={buttonClassName} to={item.url}>{item.label}</Link>:<button onClick={() => {item.action(); setOpen(false)}} className={buttonClassName}>{item.label}</button>}
						</li>)
				}
			</ul>
		</div>}
	</div>
	{open&&<div className="fixed top-0 left-0 w-full h-screen z-10" onClick={() => setOpen(false)}>&nbsp;</div>}
	</>
}