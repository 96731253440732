import React, {useEffect, useState, useRef} from 'react';
import {
  Link,
  useParams,
  useRouteMatch,
  useSearchParams,
  useNavigate
} from "react-router-dom";
import {MenuButton, Paginator, Toast, Select, BrandingStatus} from '../dsb';
import {useAuthRequest} from '../auth.js';
import {useCookies} from 'react-cookie';
import Layout from '../components/layout';
import numeral from 'numeral';
import moment from 'moment';
import { ArrowUpIcon, ArrowDownIcon, XMarkIcon, ArrowPathIcon, PaperClipIcon, TrashIcon} from '@heroicons/react/24/solid'

const options = [
	{label: 'Pending', value: 'pending'},
	{label: 'In Progress', value: 'in_progress'},
	{label: 'Completed', value: 'complete'},
	{label: 'Archived', value: 'archive'},
];

export default function CustomBrandingRequests({match, history}) {

	const {authFetch} = useAuthRequest();

	const {id} = useParams();

	const navigate = useNavigate();

	const [request, setRequest] = useState(null);

	const [imageId, setImageId] = useState(null);

	const [imageIds, setImageIds] = useState([]);

	const [messageTxt, setMessageTxt] = useState('');

	const [message, setMessage] = useState(false);

	const [disabled, setDisabled] = useState(false);

	const [uploading, setUploading] = useState(false);

	const [status, setStatus] = useState('');

	const statusRef = useRef();

	const commentsRef = useRef();

	const attachImage = async(files) => {
		setUploading(true);

		const formData = new FormData();

		for(var i = 0; i < files.length; i++) {
			
			formData.append('image[]', files[i]);
		
		}
		

		const response = await authFetch(`/api/admin/images`, 'POST', formData);

		const {image_ids} = response;

		const _imageIds = imageIds.concat(image_ids);

		setImageIds(_imageIds);

		setUploading(false);

	}

	const sendMessage = async() => {

		setDisabled(true);

		const formData = new FormData();

		formData.append('comment_txt', messageTxt);

		imageIds.forEach(imageId => {
			
			formData.append('image_id[]', imageId);
		
		})

		const response = await authFetch(`/api/admin/service-requests/${id}/comment`, 'POST', formData);

		const { message, error } = response;

		if (message) {
			setMessage(message);
			setMessageTxt('');
			setImageIds([]);
			loadRequest();
		}

		if (error) {
			setMessage(message);
		}

		setDisabled(false);
	}

	const removeImage = (imageId) => {
		var _imageIds = [...imageIds];

		const index = _imageIds.indexOf(imageId);

		
		_imageIds.splice(index, 1);

		setImageIds(_imageIds);
	}

	const saveStatus = async() => {

		const formData = new FormData();

		formData.append('status', status);

		const response = await authFetch(`/api/admin/custom-branding-requests/${id}`, 'POST', formData);

		const { error, message} = response;

		if (message) {
			setMessage(message);
			loadRequest();
			
		}

		if (error) {
			setMessage(error);
		}
	}

	const loadRequest = async() => {
		const response = await authFetch(`/api/admin/custom-branding-requests/${id}`);

		const { request, error} = response;

		if (request) {
			setRequest(request);
			setStatus(request.status);
			setTimeout(() => {
				commentsRef.current.scrollTo(0, commentsRef.current.scrollHeight);
			}, 300)
			
		}
	}

	useEffect(() => {
		loadRequest();
	}, []);

	const form = request!=null&&request.data!=null?JSON.parse(request.data):{};

	const fields = [];

	for(var x in form) {
		fields.push({label: x.replace('_', ' '), value: form[x]});
	}

	return <Layout current={'branding'}>
		{request!=null&&<>
			<h3 className="text-white text-2xl md:text-4xl font-bold pt-4 mb-4"><span className="truncate">Custom Branding Request</span> #{request.id}</h3>
			<div className="mt-3 mb-6 sm:my-3 text-xs text-slate-200 sm:text-sm space-x-2">
				<BrandingStatus status={request.status}/>
				<span>&middot;</span>
				<Link className="text-cyan-400 text-xs sm:text-sm" to={`/stores/${request.user_id}`}>{request.user.store_name}</Link>
				<span className="hidden sm:inline-block">&middot;</span>
				
				<span className="hidden sm:inline-block">Submitted {moment(request.created_at).fromNow()}</span>
			</div>
			<div className="md:grid grid-cols-5 gap-4 space-y-4 md:space-y-0">
			<div className="md:col-span-3 ">
				<div className="bg-slate-800 rounded-md col-span-2 h-full">
					<div className="px-4 py-3 border-b border-slate-600">
						<h3 className="text-xl font-bold text-white">Messages</h3>

					</div>
					<div className="px-4 py-4 h-full flex-col items-stretch">
						<div ref={commentsRef} className="h-96 bg-slate-800 border-t border-l border-r border-slate-600 w-full overflow-auto px-4 py-4 rounded-t-md">
							{
								request.comments.map(comment => <div className="mb-2">
										<p className="text-sm text-slate-200 mb-2">{
											comment.user.admin==0?<Link className=" mr-1" to={`/stores/${comment.user.id}`}>{comment.user.store_name}: </Link>:<span className="text-white font-bold mr-1">{comment.user.name}: </span>
										}
										{
											comment.comment_txt
										}
										</p>

										{
											comment.attachments.length>0&&<div className="flex flex-wrap mb-2">
												{
													comment.attachments.map(attachment => <button key={`image-${attachment.id}`} onClick={() => setImageId(attachment.image_id)}><img src={`https://res.cloudinary.com/wigmarket/w_200,h_200,c_fill/${attachment.image_id}.jpg`} className="w-32 h-32"/></button>)
												}
											</div>
										}
										<small className="text-xs text-slate-400 block">{moment(comment.created_at).fromNow()}</small>
									</div>)
							}
							{
								request.comments.length==0&&<div className="h-full w-full flex items-center justify-center">
									<span className="text-sm text-slate-400">No comments for this request</span>
								</div>
							}
						</div>
						<div className="">
						<textarea value={messageTxt} onChange={e => setMessageTxt(e.target.value)} className="text-slate-200 text-sm w-full border border-slate-500 bg-slate-800 px-4 py-3 rounded-b-md" placeholder="Write your message..."></textarea>
						
						<div className="flex items-center space-x-2">
							<button onClick={() => sendMessage()} disabled={disabled} className="disabled:opacity-50 px-10 text-sm rounded-md font-bold py-3 border-indigo-600 bg-indigo-600 text-white">Send</button>
							<input disabled={uploading} className="hidden w-0 h-0" type="file" id="myFile" name="filename" multiple onChange={(e) => attachImage(e.target.files)}/>
							<label htmlFor="myFile" disabled={uploading} className=" disabled:opacity-50 px-4 rounded-md  bg-slate-700 py-3 h-full flex items-center text-slate-200 cursor-pointer">{uploading==false?<PaperClipIcon className="w-5 h-5"/>:<ArrowPathIcon className="w-5 h-5 animate-spin"/>}</label>

						</div>
						<div className="flex items-center mt-2 flex-wrap">
							{
								imageIds.map((imageId, i) => <div className="relative my-1 mx-1" key={`image-${imageId}-${i}`}>
										<button onClick={() => removeImage(imageId)} className="hover:bg-indigo-600 absolute right-1 top-1 bg-slate-800 px-1 py-1 rounded-sm"><TrashIcon className="w-5 h-5 text-white"/></button>
										<img src={`https://res.cloudinary.com/wigmarket/h_200,c_fill/${imageId}.jpg`} className="h-20 w-20 object-contain rounded-md bg-slate-600"/>
									</div>)
							}
						</div>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-slate-800 rounded-md col-span-2">
				<div className="px-4 py-3 border-b border-slate-600">
					<h3 className="text-xl font-bold text-white">Form Responses</h3>

				</div>
				<div className="px-4 py-3">
					<div className="space-y-2">
					{
						fields.map(field => {
							if (field.label == 'image id') {

								return <div>
								<label className="block text-xs uppercase text-slate-400 font-bold">Images</label>
								{field.value.map(imageId => <div key={`image-${imageId}`}>
									
									<button onClick={() => setImageId(imageId)} className="cursor-pointer rounded-md"><img src={`https://res.cloudinary.com/wigmarket/w_300,c_fill/${imageId}.jpg`} className="w-48 rounded-md"/></button>
								</div>)}</div>
								
							} else if (field.label == 'images') {
								return null;
							}

							return <div>
								<label className="block text-xs uppercase text-slate-400 font-bold">{field.label}</label>
								<p className="text-slate-200">{field.value}</p>
							</div>
						})
					}

					</div>
					</div>

					<div className="mt-4 pt-4 border-slate-600 mb-8 md:mb-0 border-t block">
					<div className="px-4 pb-4 space-y-2">
					<div>
						<label className="block mb-1 text-xs uppercase text-slate-400 font-bold">Request Status</label>
						<Select className="w-full md:w-auto" value={status} onSelect={(e) => setStatus(e.target.value)} options={options} defaultValue={request.status}/>
					</div>
					<div>
						<button onClick={() => saveStatus()} className="hover:bg-indigo-700 bg-indigo-600 text-white rounded-md block w-full py-3 font-bold">Save Status</button>
					</div>
					</div>
				</div>
			</div>
			</div>
		</>}

		{
			imageId!=null&&<div className="z-50  bg-slate-900/50 backdrop-blur-sm fixed top-0 left-0 w-screen h-screen flex items-center justify-center">
				<button onClick={() => setImageId(null)} className="fixed md:absolute top-0 md:top-5 right-0 md:right-10 text-white"><XMarkIcon className="w-10 h-10"/></button>
				<div className="w-full h-full overflow-auto md:flex md:items-center md:justify-center">
				<img src={`https://res.cloudinary.com/wigmarket/${imageId}.jpg`} className="max-w-none md:max-w-full md:w-full md:max-h-screen md:max-w-screen"/>
				</div>
			</div>
		}
		<Toast message={message} onReset={() => setMessage(false)}/>
	</Layout>

}