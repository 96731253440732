import React, {useEffect, useState, useRef} from 'react';
import {
  Link,
  useParams,
  useRouteMatch,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import {useCookies} from 'react-cookie';
import {useAuthRequest} from '../auth.js';
import moment from 'moment';
import {PlanBadge, Paginator, OrderStatus, MenuButton,SetupStatus,  Modal, Toast, Button, Card, Label, Select} from '../dsb.js';
import numeral from 'numeral';
import Layout from '../components/layout';
import {  ChevronLeftIcon, ChevronRightIcon,  FlagIcon,  PencilSquareIcon,  MagnifyingGlassIcon} from '@heroicons/react/24/solid'

const statuses = [
	{status: 'pending', label: 'Pending', description: 'Orders that are awaiting payment'},
	{status: 'paid', label: 'Paid', description: 'Orders that have been paid and are awaiting fulfillment'},
	{status: 'fulfilled', label: 'Fulfilled', description: 'Orders that have been shipped'},
	{status: 'cancelled', label: 'Cancelled', description: 'Orders that have been cancelled by the seller'},
	{status: 'archived', label: 'Archived', description: 'Orders that are older than 30 days'}
];

export default function StoreRequest() {

	const {id} = useParams();

	const searchResultsRef = useRef();

	const {authFetch} = useAuthRequest();

	const searchRef = useRef();

	const navigate = useNavigate();

	const [setup, setSetup] = useState(null);

	const [noteTxt, setNoteTxt] = useState('');

	const [searchResults, setSearchResults] = useState(null);

	const [query, setQuery] = useState('');

	const [shopifyDomain, setShopifyDomain] = useState('');

	const [archiveModal, setArchiveModal] = useState(false);
	const [unarchiveModal, setUnarchiveModal] = useState(false);

	const [refundModal, setRefundModal] = useState(false);

	const [connectModal, setConnectModal] = useState(false);

	const [disabled, setDisabled] = useState(false);

	const [cancelOrder, setCancelOrder] = useState(true);

	const [message, setMessage] = useState(false);

	const [noteModal, setNoteModal] = useState(false);

	const [manual, setManual] = useState(false);

	const loadStoreRequest = async() => {
		const response = await authFetch(`/api/admin/store-requests/${id}`);

		const {setup, error} = response;

		if (setup) {
			setSetup(setup);
			if (setup.store != null && setup.can_change_store == 1) {
				setShopifyDomain(setup.store.shopify_domain);
			}
		}

		if (error) {
			navigate(`/setups/pending`);
		}
	}

	const checkShopifyStore = (e) => {
		if (e.target.checked) {
			setShopifyDomain(e.target.value);
		}
	}

	const submitRefund = async() => {
		const formData = new FormData();

		if (cancelOrder) {
			formData.append('cancel_order', 1);
		}
		const response = await authFetch(`/api/admin/store-requests/${id}/refund`, 'POST', formData);

		const {message, error} = response;

		if (message) {
			setMessage(message);
			setRefundModal(false);
			loadStoreRequest();
		}

		if (error) {
			setMessage(error);
		}
	}

	const submitNote = async() => {
		setDisabled(true);
		const formData = new FormData();

		formData.append('note_txt', noteTxt);

		const response = await authFetch(`/api/admin/store-requests/${id}/notes`, 'POST', formData);

		const {message, error} = response;

		if (message) {
			setMessage(message);
			setNoteModal(false);
			loadStoreRequest();
			setNoteTxt('');
		}

		if (error) {
			setMessage(error);
		}

		setDisabled(false);
	}

	const changePage = async(page=1) => {

		const response = await authFetch(`/api/admin/stores?q=${escape(query)}&active=1&page=${page}`, 'GET');

		const {stores, error} = response;

		if (stores) {
			setShopifyDomain('');
			setSearchResults(stores);
			searchResultsRef.current.scrollTop = 0;
		}

		if (error) {
			setMessage(error);
		}

		setDisabled(false);
	}

	const archiveSetup = async() => {

		setDisabled(true);
		const response = await authFetch(`/api/admin/store-requests/${id}/archive`, 'POST');

		const {message, error} = response;

		if (message) {
			setMessage(message);
			loadStoreRequest();
		} else if (error) {
			setMessage(error);
		}

		setArchiveModal(false);
		setDisabled(false);
	}

	const unarchiveSetup = async() => {

		setDisabled(true);
		const response = await authFetch(`/api/admin/store-requests/${id}/unarchive`, 'POST');

		const {message, error} = response;

		if (message) {
			setMessage(message);
			loadStoreRequest();
		} else if (error) {
			setMessage(error);
		}

		setUnarchiveModal(false);
		setDisabled(false);
	}

	const triggerImport = async() => {
		setDisabled(true);

		const response = await authFetch(`/api/admin/store-requests/${id}/trigger`, 'POST');

		const {message, error} = response;

		if (message) {
			setMessage(message);
			setManual(false);
			loadStoreRequest();
		}

		if (error) {
			setMessage(error);
		}

		setDisabled(false);
	}

	const searchStores = async(e) => {
		e.preventDefault();

		setDisabled(true);

		const _query = searchRef.current.value;

		setQuery(_query);

		const response = await authFetch(`/api/admin/stores?q=${escape(_query)}&active=1`, 'GET');

		const {stores, error} = response;

		if (stores) {
			setShopifyDomain('');
			setSearchResults(stores);
		}

		if (error) {
			setMessage(error);
		}

		setDisabled(false);

		return false;
	}

	const connectShopifyStore = async() => {
		setDisabled(true);
		const formData = new FormData();

		formData.append('shopify_domain', shopifyDomain);
		const response = await authFetch(`/api/admin/store-requests/${id}`, 'POST', formData);

		const {message, error} = response;

		if (message) {
			setMessage(message);
			setConnectModal(false);
			loadStoreRequest();
		}

		if (error) {
			setMessage(error);
		}

		setDisabled(false);
	}

	useEffect(() => {
		loadStoreRequest();
	}, []);

	const json = setup!=null&&setup.markups!=null?JSON.parse(setup.markups):[];

	return <Layout current={`setups`}>
		{setup!=null&&<>
			<div className="md:flex justify-between items-center">
			<h3 className="text-white text-4xl font-bold pt-4 mb-4">Expert Setup #{setup.id}</h3>
				<div className="space-x-2">
					{(setup.status=='pending'||setup.status=='in_progress')&&<button  onClick={() => setArchiveModal(true)} className="w-full text-center md:w-auto md:inline-block mb-4 md:mb-0 bg-slate-800 text-white px-4 py-2 rounded-md shadow-xl text-sm hover:bg-indigo-600">Archive</button>}
					{(setup.status=='archived')&&<button onClick={() => setUnarchiveModal(true)} className="w-full text-center md:w-auto md:inline-block mb-4 md:mb-0 bg-slate-800 text-white px-4 py-2 rounded-md shadow-xl text-sm hover:bg-indigo-600">Un-Archive</button>}
					{setup.refund_id==null&&<button onClick={() => setRefundModal(true)} className="w-full text-center md:w-auto md:inline-block mb-4 md:mb-0 bg-slate-800 text-white px-4 py-2 rounded-md shadow-xl text-sm hover:bg-indigo-600">Issue Refund</button>}
				</div>
			</div>
			<div className="mb-2 space-x-2">
				<SetupStatus status={setup.status}/>
				{setup.refund_id!=null&&<span className="bg-amber-600 px-2 py-1 rounded-lg text-white p  text-xs font-bold">Refunded</span>}
			</div>
			{
				setup.store==null&&<div className="bg-slate-200 px-4 py-3 rounded-md mt-4 space-x-2 flex items-center">
				<div>
					<FlagIcon className="w-10 h-10 text-slate-800"/>
				</div>
				<div>
					<h3 className="font-bold text-base text-slate-800">Action Required</h3>
					<p className="text-sm">This request requires an admin to create a Shopify store on the user's behalf. <button className="text-sm text-indigo-600 font-bold">Instructions on how to proceed here</button></p>
				</div>
				</div>
			}
			
			<div className="lg:grid grid-cols-9 gap-4">
			<div className="col-span-2">
				<div className="bg-gray-800 rounded-md mt-4 mb-4">
					<div className="px-4 py-3">
						<Label>Store</Label>
						<p className="mb-2 flex items-center justify-between">{
							setup.store!=null?<>
								<Link to={`/stores/${setup.store.id}`} className="text-sm text-cyan-400 hover:text-indigo-400">{setup.store.store_name}</Link>
								{
									setup.can_change_store==1&&<button onClick={() => setConnectModal(true)} className="text-cyan-400 hover:text-indigo-400 text-sm"><PencilSquareIcon className="w-4 h-4 block"/></button>
								}
							</>:<>
								<button className="text-cyan-400 hover:text-indigo-400 text-sm" target="_blank" onClick={() => setConnectModal(true)}>This request has a manual step</button>

							</>
						}</p>
						<Label>Customer Info</Label>
						<p className="text-sm text-slate-400 mt-1">{setup.first_name} {setup.last_name}</p><a className="text-sm  mb-2 block text-cyan-400 hover:text-indigo-400" href={`mailto:${setup.email}`}>{setup.email}</a>
						<Label>Message</Label>
						<p className="text-sm text-slate-400 mt-1 mb-2">{setup.message_txt!=null?setup.message_txt:'n/a'}</p>
						<Label>Submitted</Label>
						<p className="text-sm text-slate-400">{moment(setup.created_at).fromNow()}</p>
					</div>
				</div>
				<button onClick={() => setManual(true)} className="bg-indigo-600 active:bg-black hover:shadow-lg text-white rounded-lg block text-center w-full font-bold py-3">Run Setup Now</button>
			</div>
			<div className="col-span-6">
			<div className="bg-gray-800 rounded-md mt-4">
				<table className="w-full">
					<thead className="text-left border-b border-slate-700 text-slate-300 text-xs uppercase">
						<tr>
							<th className=" px-4 py-3">Category</th>
							<th className="px-4 py-3">Markup</th>
							
						</tr>
					</thead>
					<tbody className="divide-y divide-slate-700 w-full  text-sm text-gray-200 border-b border-slate-700">
						{
							json.map(category => <tr key={`category-${category.slug}`}>
									<td className="px-4 py-3">
										<span>{category.slug}</span>
									</td>
									<td className="px-4 py-3">
										<strong className="text-white">{category.markup}x</strong>
									</td>
								</tr>)
						}
						{
							json.length==0&&<tr>
								<td className="px-4 py-20 text-center" colspan="2">
									<p>This may be a legacy setup request. Markups and categories can't be displayed.</p>
								</td>
							</tr>
						}
					</tbody>

					</table>
			</div>
			<div className="bg-gray-800 rounded-md mt-4">
				<div className="px-4 py-3 border-b border-slate-700 justify-between flex items-center">
					<h3 className="text-white font-bold">Notes</h3>
					<button onClick={() => setNoteModal(true)} className="text-cyan-400 text-sm">Add Note</button>
				</div>
				<div className="divide-y divide-slate-700">
				{
					setup.notes.map(note => <div className="px-4 py-3" key={`note-${note.id}`}>
						<p className="text-slate-200 text-sm">{note.note_txt}</p>
						<small className="text-slate-400">Added by <strong>{note.user==null?'System':note.user.name}</strong> {moment(note.created_at).fromNow()}</small>
					</div>)
				}
				{
					setup.notes.length==0&&<div className="px-4 py-6 text-center">
						<p className="text-slate-200 text-sm">No notes added</p>
					</div>
				}
				</div>
				
			</div>
			</div>
			
			</div>
			<Modal open={manual} close={() => setManual(false)} title="Are you sure?" primary={{label: 'Run Setup', disabled: (disabled||setup.status!='pending'),  action: () => triggerImport()}} secondary={{label:'Close', action: () => setManual(false)}}>
				<>
					<p className="text-white mb-4">Setups automatically run nightly around 2:00 am, but can trigger the import process now.</p>
					<p className="text-white">The process takes 12 minutes on average.</p>
				</>
			</Modal>
			<Modal open={connectModal} close={() => setConnectModal(false)} title="Connect to Shopify Store" primary={{label: 'Connect to Store', disabled: (shopifyDomain==''),  action: () => connectShopifyStore()}} secondary={{label:'Close', action: () => setConnectModal(false)}}>
				<>
					<Label>Step 1. Create a new store</Label>
					<p className="text-slate-400 mb-2">If you have not already done so, create a new Shopify development store for this user by navigating <a target="_blank" href="https://partners.shopify.com/796744/stores/new?store_type=client_store" className="text-cyan-400">here</a>.</p>
					<Label>Step 2. Install Dropship Beauty</Label>
					<p className="text-slate-400 mb-2">Install the app on the development store, which you can do <a target="_blank" href="https://partners.shopify.com/796744/apps/2473921/test" className="text-cyan-400">here</a>.</p>
					<Label>Step 3. Associate this request with the new store</Label>
					<p className="text-slate-400 mb-1">Choose the user to connect to this setup</p>
					<form onSubmit={searchStores}>
						<div className="grid grid-cols-6">
							<input ref={searchRef} type="text" className="col-span-5 px-4 py-3 bg-white rounded-l-md w-full block text-sm" placeholder="Search by shopify domain, store name, email address..." defaultValue={query} />
							<button disabled={disabled} className="rounded-r-md bg-slate-700 disabled:opacity-50 text-white py-3 px-5 flex items-center justify-center"><MagnifyingGlassIcon className="w-5 h-5 block"/></button>
						</div>
					</form>
					{
						searchResults!=null&&searchResults.data.length>0&&<div ref={searchResultsRef} className="max-h-96 overflow-auto divide-y divide-slate-700 bg-slate-900 mt-2 rounded">
							{
								searchResults.data.map(store => <div key={`search-${store.id}`} className="flex items-center py-3 space-x-4 px-2">
										
											<input id={store.shopify_domain} type="radio" name="shopify_domain" value={store.shopify_domain} checked={shopifyDomain==store.shopify_domain} onChange={checkShopifyStore}/>
										
										<label htmlFor={store.shopify_domain}>
											<h3 className="text-white font-bold block">{store.store_name}</h3>
											<p className="text-slate-200 text-xs">{store.shopify_domain}.myshopify.com &middot; {store.email}</p>
										</label>
									</div>)
							}
							{
								searchResults.last_page>1&&<div className="grid grid-cols-2 divide-x divide-slate-700">
									<button onClick={() => changePage(searchResults.current_page-1)} disabled={searchResults.current_page==1} className="py-3 rounded-l disabled:opacity-50 text-white text-center flex items-center justify-center"><ChevronLeftIcon className="w-5 h-5"/></button>
									<button onClick={() => changePage(searchResults.current_page+1)} disabled={searchResults.current_page==searchResults.last_page} className="py-3 rounded-r disabled:opacity-50 text-white flex items-center justify-center"><ChevronRightIcon className="w-5 h-5"/></button>
								</div>
							}
						</div>
					}
					{
						searchResults!=null&&searchResults.total==0&&<div className="text-center px-2 py-3 mt-4 text-slate-400">
							<span>No stores found matching your search query</span>
						</div>
					}
				</>
			</Modal>
			<Modal open={refundModal} close={() => setRefundModal(false)} title="Refund User?" primary={{label: 'Confirm Refund', disabled,  action: () => submitRefund()}} secondary={{label:'Close', action: () => setRefundModal(false)}}>
				<>
					<p className="text-base text-slate-200 mb-2">Are you sure you want to refund <strong className="text-cyan-400">{`${setup.first_name} ${setup.last_name}`}</strong> for <strong className="text-white">$25</strong>?</p>
					<div className="flex items-center space-x-2"><input type="checkbox" className="w-4 h-4 block bg-white rounded-sm" checked={cancelOrder} onChange={(e) => setCancelOrder(e.target.checked)} id="cancel-order"/>
					<label htmlFor="cancel-order" className="text-slate-400 text-sm">Cancel this order as well</label></div>
				</>
			</Modal>
			<Modal open={noteModal} close={() => setNoteModal(false)} title="Add Note" primary={{label: 'Add Note', disabled: (disabled||noteTxt.length==0),  action: () => submitNote()}} secondary={{label:'Close', action: () => setNoteModal(false)}}>
				<>
					<Label>Note</Label>
					<textarea value={noteTxt} onChange={(e) => setNoteTxt(e.target.value)} rows={3} placeholder="Write your note here..." className="block w-full px-3 py-2 bg-slate-700 rounded-md text-slate-200"></textarea>
				</>
			</Modal>
			<Modal open={archiveModal} close={() => setArchiveModal(false)} title="Archive Setup?" primary={{label: 'Archive', disabled: (disabled),  action: () => archiveSetup()}} secondary={{label:'Close', action: () => setArchiveModal(false)}}>
				<>
					<p className="text-base text-slate-200 mb-2">Are you sure you want to archive this setup?</p>
				</>
			</Modal>
			<Modal open={unarchiveModal} close={() => setUnarchiveModal(false)} title="Un-archive Setup?" primary={{label: 'Un-Archive', disabled: (disabled),  action: () => unarchiveSetup()}} secondary={{label:'Close', action: () => setUnarchiveModal(false)}}>
				<>
					<p className="text-base text-slate-200 mb-2">Are you sure you want to un-archive this setup?</p>
				</>
			</Modal>
		</>}
		<Toast message={message} onReset={() => setMessage(false)}/>
	</Layout>
}