import React, {useEffect, useState, useRef} from 'react';
import {
  Link,
  useParams,
  useRouteMatch,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import {useCookies} from 'react-cookie';
import {useAuthRequest} from '../auth.js';
import moment from 'moment';
import {PlanBadge, Paginator, OrderStatus, variantToString, MenuButton, Modal, Toast, Button, Card, Label, Select} from '../dsb.js';
import numeral from 'numeral';
import Layout from '../components/layout';
import { BeakerIcon, TrashIcon, DocumentTextIcon, LinkIcon, BuildingStorefrontIcon, ShoppingBagIcon, PencilSquareIcon, ChartBarIcon, UsersIcon, MagnifyingGlassIcon, CheckIcon, XMarkIcon} from '@heroicons/react/24/solid'

const statuses = [
	{status: 'pending', label: 'Pending', description: 'Orders that are awaiting payment'},
	{status: 'in_progress', label: 'In Progress', description: 'Orders that have been paid and are awaiting fulfillment'},
	{status: 'complete', label: 'Completed', description: 'Orders that have been shipped'},
	{status: 'cancelled', label: 'Cancelled', description: 'Orders that have been cancelled by the seller'},
	{status: 'archived', label: 'Archived', description: 'Orders that are older than 30 days'}
];

export default function StoreRequests() {

	const {authFetch} = useAuthRequest();

	const { type } = useParams();

	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();

	const [setups, setSetups] = useState(null);

	const loadStoreRequests = async(page=1) => {
		const response = await authFetch(`/api/admin/store-requests?status=${type}&page=${page}`);

		const { setups, error } = response;

		if (setups) {
			setSetups(setups);
		}

		if (error) {

		}
		
	}

	useEffect(() => {
		loadStoreRequests();
	}, [searchParams, type]);

	return <Layout current={'setups'}>
		<>

			<h3 className="text-white text-2xl md:text-4xl font-bold pt-4 mb-4">Expert Setup Requests</h3>
			<div className="hidden md:block space-x-3 flex items-center">
				{
					statuses.map(tab => <Link className={`${tab.status==type?'bg-slate-800 shadow-xl text-white border border-white rounded-md':'text-slate-300 border border-transparent'} px-4 py-2 text-sm inline-block`} key={`tab-${tab.status}`} to={`/setups/${tab.status}`}>{tab.label}</Link>)
				}
			</div>
			<div className="md:hidden ">
				<Select className="md:w-auto md:block-inline block w-full" options={statuses.map(status => ({label: status.label, value: status.status}))} value={type} onSelect={(e) => navigate(`/setups/${e.target.value}`)}/>
			</div>
			<div>
				<div className="overflow-x-auto bg-gray-800 rounded-md mt-4 w-full">
					{setups!=null&&<table className="table-auto overflow-scroll w-full">
					<thead className="text-left border-b border-slate-700 text-slate-300 text-xs uppercase">
						<tr>
							<th className=" px-4 py-3">ID</th>
							<th className="px-4 py-3">Name</th>
							<th className="px-4 py-3">Email</th>
							<th className="px-4 py-3">Needs Store?</th>
							<th className="px-4 py-3">Has Notes?</th>
							<th className="px-4 py-3">Submitted</th>
							<th className="px-4 py-3">Action</th>
							
						</tr>
					</thead>
					<tbody className="text-sm text-slate-200">
						{
							setups.data.map(setup => <tr key={`store-setup-${setup.id}`}>
									<td className="px-4 py-3">
										<Link to={`/setup/${setup.id}`} className="text-cyan-400 hover:text-indigo-600">{setup.id}</Link>
									</td>
									<td className="px-4 py-3">
										<span>{setup.first_name} {setup.last_name}</span>
									</td>
									<td className="px-4 py-3">
										<a href={`mailto:${setup.email}`} className="text-cyan-400 hover:text-indigo-600">{setup.email}</a>
									</td>
									<td className="px-4 py-3">
										{setup.store!=null?<span>No</span>:<span className="font-bold text-lime-400">Yes</span>}
									</td>
									<td className="px-4 py-3">
										{setup.notes_count>0?<DocumentTextIcon className="block text-rose-400 w-5 h-5"/>:<span>&nbsp;</span>}
									</td>
									<td className="px-4 py-3">
										<span>{moment(setup.created_at).fromNow()}</span>
									</td>
									<td className="px-4 py-3">
										<Link to={`/setup/${setup.id}`} className="text-cyan-400 hover:text-indigo-600">View Request</Link>
									</td>
								</tr>)
						}
						
						{
							setups.data.length==0&&<tr>
								<td colspan={6} className="py-20 px-4 text-center">
									<p>No expert setup requests found..</p>
								</td>
							</tr>
						}
					</tbody>
					</table>}
					
					</div>

					{
						setups!=null&&<div className="my-4 py-4 md:py-0">
							<Paginator data={setups} changePage={loadStoreRequests}/>
						</div>
					}
			</div>
		</>
	</Layout>
}