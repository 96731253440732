import React, {useEffect, useState, useRef} from 'react';
import {
  Link,
  useParams,
  useRouteMatch,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import {useCookies} from 'react-cookie';
import {useAuthRequest} from '../auth.js';
import moment from 'moment';
import {PlanBadge, Paginator, OrderStatus, MenuButton, Modal, Toast, Button, Card, Label, Select} from '../dsb.js';
import numeral from 'numeral';
import Layout from '../components/layout';
import { BeakerIcon, TrashIcon, BuildingStorefrontIcon,  PencilSquareIcon, ChartBarIcon, UsersIcon, MagnifyingGlassIcon, CheckIcon, XMarkIcon} from '@heroicons/react/24/solid'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function EditContent() {

	const quillObj = useRef();

	const urlRef = useRef();

	const [timed, setTimed] = useState(0);

	const [error, setError] = useState('');

	const [disabled, setDisabled] = useState(false);

	const [message, setMessage] = useState('');

	const labelRef = useRef();

	const publishAtRef = useRef();

	const expireAtRef = useRef();

	const titleRef = useRef();

	const {id} = useParams();

	const daysAfterRef = useRef();

	const [content, setContent] = useState('');

	const imageHandler = () => {
		const input = document.createElement('input');  
  
	    input.setAttribute('type', 'file');  
	    input.setAttribute('accept', 'image/*');  
	    input.click();  
	  
	    input.onchange = async() => {  
	      var file = input.files[0];  
	      var formData = new FormData();  
	  
	      formData.append('image[]', file);  
	  
	      var fileName = file.name;  
	  		
	  	  const response = await authFetch(`/api/admin/images`, 'POST', formData);

	  	  const {image_ids} = response;

	  	  const range = quillObj.current.getEditorSelection();  

	  	  console.log(range);
  
          var res = `https://res.cloudinary.com/wigmarket/${image_ids[0]}.jpg`; 
  
          quillObj.current.getEditor().insertEmbed(range.index, 'image', res); 

	    };  
	}

	const [modules, setModules] = useState({  
        toolbar: {  
          container: [  
            ['bold', 'italic', 'underline'],  
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],  
            [{ 'align': [] }],  
            ['link', 'image'],  
            ['clean'],  
            [{ 'color': [] }]  
          ],  
          handlers: {  
            image: imageHandler  
          }  
        }});

	const {authFetch} = useAuthRequest();

	const navigate = useNavigate();

	const [announcement, setAnnouncement] = useState(null);

	const loadAnnouncement = async() => {
		const response = await authFetch(`/api/admin/announcements/${id}`, 'GET');

		const {announcement} = response;

		setAnnouncement(announcement);
	}


	const saveContent = async() => {

		setDisabled(true);
		const title = titleRef.current.value;
		const content = quillObj.current.getEditor().root.innerHTML;
		const url = urlRef.current.value;
		const action = labelRef.current.value;
		const formData = new FormData();

		formData.append('title_txt', title);
		formData.append('content_html', content);
		formData.append('url', url);
		formData.append('action', action);

		if (timed == '0') {
			formData.append('timed', '0');
			formData.append('published_at', publishAtRef.current.value);
			formData.append('expires_at', expireAtRef.current.value);
			formData.append('days_after_joining', 0);
		} else {

			formData.append('timed', '1');
			formData.append('days_after_joining', daysAfterRef.current.value);
		}

		const response = await authFetch(`/api/admin/announcements`, 'POST', formData);

		const {error, message} = response;

		if (error) {
			setDisabled(false);
			setError(error);
		}

		if (message) {
			setMessage(message);
			setTimeout(() => {
				navigate(`/content`);
			}, 1000);
		}

	}
	
	useEffect(() => {
		if (announcement) {
			console.log(announcement);
			titleRef.current.value = announcement.title_txt;
			urlRef.current.value = announcement.url;
			labelRef.current.value = announcement.action;
			quillObj.current.getEditor().root.innerHTML = announcement.content_html;
			setTimed(announcement.timed+'');
			if (announcement.timed == '0') {
				if (announcement.published_at != null)
					publishAtRef.current.value = moment(announcement.published_at).format('MM/DD/YYYY');
				if (announcement.expires_at != null)
					expireAtRef.current.value = moment(announcement.expires_at).format('MM/DD/YYYY');
			} else {
				daysAfterRef.current.value = announcement.days_after_joining;
			}
		}
	}, [announcement]);

	useEffect(() => {
		if (id) {

			loadAnnouncement();
		}
		
	}, []);

	


	return <Layout current={`content`}>
		<>

			<h3 className="text-white text-2xl md:text-4xl font-bold pt-4 mb-4">{id?'Edit':'New'} Announcement</h3>

			<div className="bg-slate-800 rounded-md mt-4 px-4 py-3 mb-4">
			<div className="space-y-4">
			<div>

			<label className="text-white mb-1 text-lg font-bold block">Content Details</label>
			</div>
			<div>
			<label className="text-white mb-1 text-sm font-bold block">Title</label>
			<input type="text" ref={titleRef} className="bg-slate-600 text-slate-100 rounded px-4 py-3 text-sm block w-full" placeholder=""/>
			</div>
			<div>
				<label className="text-white mb-1 text-sm font-bold block">Content</label>
				<div className=" text-white">
					<ReactQuill modules={modules} ref={quillObj} theme="snow" rows={10}/>
				</div>
			</div>
			<div className="grid grid-cols-2 gap-4">
				<div>
					<label className="text-white mb-1 text-sm font-bold block">Button Link</label>
					<input ref={urlRef} type="url" className="bg-slate-600 text-slate-100 rounded px-4 py-3 text-sm block w-full" placeholder="https://..."/>
				</div>
				<div>
					<label className="text-white mb-1 text-sm font-bold block">Button Label</label>
					<input ref={labelRef} type="text" className="bg-slate-600 text-slate-100 rounded px-4 py-3 text-sm block w-full" placeholder="Go to link"/>
				</div>
			</div>


			</div>
			</div>
			<div className="bg-slate-800 rounded-md mt-4 px-4 py-3 mb-4">
			<div className="space-y-4">
			<label className="text-white mb-1 text-lg font-bold block">Card Type</label>
			<div className="grid grid-cols-2 gap-4">
				<div className="space-y-4">
					<div className="flex items-center space-x-2">
						<input checked={timed=='0'} type="radio" className="w-5 h-5" value="0" name="timed" onChange={(e) => setTimed(e.target.value)} id="timed-0"/>
						<label className="text-white text-sm  block" htmlFor="timed-0">Publish at certain date</label>
					</div>
					<div>
						<label className="text-white mb-1 text-sm font-bold block">Publish date</label>
						<p className="text-xs text-slate-400 block mb-1">Leave blank to save as draft</p>
						<input disabled={timed=='1'} ref={publishAtRef} type="text" className="disabled:opacity-50 bg-slate-600 text-slate-100 rounded px-4 py-3 text-sm block w-full" placeholder="MM/DD/YYYY"/>
					</div>
					<div>
						<label className="text-white mb-1 text-sm font-bold block">Expire at</label>
						<p className="text-xs text-slate-400 block mb-1">Leave blank to never expire</p>
						<input disabled={timed=='1'} ref={expireAtRef} type="text" className="disabled:opacity-50 bg-slate-600 text-slate-100 rounded px-4 py-3 text-sm block w-full" placeholder="MM/DD/YYYY"/>
					</div>
				</div>
				<div className="space-y-4">
					<div className="flex items-center space-x-2">
						<input checked={timed=='1'} type="radio" className="w-5 h-5" value="1" name="timed" onChange={(e) => setTimed(e.target.value)} id="timed-1"/>
						<label className="text-white text-sm  block" htmlFor="timed-1">Show after sign up</label>
					</div>
					<div>
						<label className="text-white mb-1 text-sm font-bold block">Show how many days after sign up?</label>
						<p className="text-xs text-slate-400 block mb-1">Leave blank to save as draft</p>
						<input disabled={timed=='0'} ref={daysAfterRef} type="number" className="disabled:opacity-50 bg-slate-600 text-slate-100 rounded px-4 py-3 text-sm block w-full" placeholder="1"/>
					</div>
				</div>
				
			</div>
			</div>
			</div>
			<div className="mb-10">
			{
				error!=''&&<p className="text-rose-400 text-sm mb-2">{error}</p>
			}
			{
				message!=''&&<p className="text-lime-400 text-sm mb-2">{message}</p>
			}
			<button onClick={() => saveContent()} disabled={disabled} className="disabled:opacity-50 bg-indigo-600 text-white font-bold px-6 py-2 text-sm rounded">Save Content</button>
			</div>
		</>
	</Layout>
}