import React, {useEffect, useState, useRef} from 'react';
import {
  Link,
  useParams,
  useRouteMatch,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import {useCookies} from 'react-cookie';
import {useAuthRequest} from '../auth.js';
import moment from 'moment';
import {Paginator, Select, Card, Button, Toast, Modal, Label} from '../dsb.js';
import Layout from '../components/layout';
import numeral from 'numeral';
import { ChevronLeftIcon, ChevronRightIcon, BeakerIcon, BuildingStorefrontIcon, ChartBarIcon, UsersIcon, MagnifyingGlassIcon, CheckIcon, XMarkIcon} from '@heroicons/react/24/solid'

export default function Collection() {

	const {authFetch} = useAuthRequest();

	const { id } = useParams();

	const [collection, setCollection] = useState(null);

	const [storeModal, setStoreModal] = useState(false);

	const [disabled, setDisabled] = useState(false);

	const [searchResults, setSearchResults] = useState(null);

	const [store, setStore] = useState(null);

	const [storeSelectedForRemoval, setStoreSelectedForRemoval] = useState(null);

	const queryRef = useRef();

	const [message, setMessage] = useState('');

	const [users, setUsers] = useState(null);

	const [exclusive, setExclusive] = useState(0);

	const saveCollection = async() => {
		const formData = new FormData();

		formData.append('exclusive', exclusive);

		const response = await authFetch(`/api/admin/collections/${id}`, 'POST', formData);

		const {message, error} = response;

		if (message) {
			setMessage(message);
			loadCollection();
		}

		if (error) {
			setMessage(error);
		}
	}

	const grantAccess = async() => {
		const response = await authFetch(`/api/admin/users/${store.id}/collections/${id}`, 'POST');

		const {message, error} = response;

		if (message) {
			setStoreModal(false);
			setMessage(message);
			loadCollection();
		}

		if (error) {
			setMessage(error);
		}
	}

	const searchStores = async(e) => {
		e.preventDefault();

		const response = await authFetch(`/api/admin/search?q=${queryRef.current.value}`);

		const { stores } = response;

		setSearchResults(stores);

		return false;
	}

	const changePage = async(page=1) => {

		if (page < 0) {
			return false;
		}

		if (page > searchResults.last_page) {
			return false;
		}

		const response = await authFetch(`/api/admin/search?q=${queryRef.current.value}&page=${page}`);

		const { stores } = response;

		setSearchResults(stores);
	}

	const removeAccess = async() => {
		setDisabled(true);

		const response = await authFetch(`/api/admin/users/${storeSelectedForRemoval.id}/collections/${id}/remove`, 'POST');

		const {message, error} = response;

		if (message) {
			setStoreSelectedForRemoval(null);
			setMessage(message);
			loadCollection();
		}

		if (error) {
			setMessage(error);
		}

		setDisabled(false);
	}

	const loadCollection = async(page=1) => {

		const response = await authFetch(`/api/admin/collections/${id}?page=${page}`);

		const {collection, users} = response;

		setCollection(collection);

		setExclusive(collection.exclusive);

		setUsers(users);

		window.scrollTo(0,0);
	}

	useEffect(() => {
		loadCollection();
	}, []);

	return <Layout current="collections">
		{collection!=null&&<>
				
				<h3 className="text-white text-4xl font-bold mt-4 mb-4">{collection.name}</h3>
				<div className="mb-4">
				<div className="">
				<Card title="Exclusive Status">
				<Select className="w-full block mb-4" options={[
						{label: 'Open for all', value: 0},
						{label: 'Exclusive', value: 1}
					]} value={exclusive} onSelect={(e) => setExclusive(e.target.value)}/>

					<Button className="" onClick={saveCollection}>Save Changes</Button>
					</Card>
				</div>
				</div>
				
				<div className="bg-gray-800 rounded-md overflow-x-auto">
					<div className="flex justify-between items-center p-4 border-b border-slate-700 mb-2">
					<h3 className="font-bold text-white">Stores with Access</h3>
					<button className="text-sm text-cyan-400" onClick={() => setStoreModal(true)}>Add Store</button>
					</div>
					{users!=null&&<table className="w-full">
					<thead className="text-left border-b border-slate-700 text-slate-300 text-xs uppercase">
							<tr>
								<th className="px-4 py-2 text-left">ID</th>
								<th className="px-4 py-2 text-left">Store Name</th>
								<th className="px-4 py-2 text-left">Access Granted</th>
								<th className="px-4 py-2 text-right">Actions</th>
							</tr>
						</thead>
						<tbody className="divide-y divide-slate-700 text-slate-400 text-sm">
							{
								users.data.map(user => <tr key={`user-${user.id}`}>
										<td className="px-4 py-2 text-left">
											<Link to={`/stores/${user.id}`}>{user.id}</Link>
										</td>
										<td className="px-4 py-2 text-left">
											<Link to={`/stores/${user.id}`}>{user.store_name}</Link>
										</td>
										<td className="px-4 py-2 text-left">
											<span>{moment(user.created_at).fromNow()}</span>
										</td>
										<td className="px-4 py-2 text-right">
											<button onClick={() => setStoreSelectedForRemoval(user)} className="text-cyan-400">Remove Access</button>
										</td>
									</tr>)
							}
							{
								users.data.length==0&&<tr>
									<td colspan={4}>
										<div className="px-4 py-20 text-center ">
											{collection.exclusive==1?<><h4 className="font-bold text-white text-base">No Stores Found</h4><span className="text-sm text-slate-400">No stores granted access to this collection</span></>:<><h4 className="font-bold text-white text-base">Open Collection</h4><span className="text-sm text-slate-400">To grant access to only certain stores, set status to Exclusive</span></>}
										</div>
									</td>
								</tr>
							}
						</tbody>
					</table>}
					<div className="px-4 py-2">
						<Paginator data={users} changePage={loadCollection}/>
					</div>
				</div>
				
		</>}
		<Modal open={storeModal} close={() => setStoreModal(false)} title="Assign Store to Collection" primary={{label: 'Grant Access', action: () => grantAccess(), disabled: store==null}} secondary={{label: 'Close', action: () => setStoreModal(false)}}>
			<Label>Search Dropship Users</Label>
			<form onSubmit={searchStores}>
			<div className="flex ">
				<input ref={queryRef} type="text" className="flex-1 rounded-l-md px-4" placeholder="Search stores by store name, shopify domain, or ID"/>
				<button className="bg-slate-700 text-white px-6 py-3 rounded-r-md"><MagnifyingGlassIcon className="w-5 h-5"/></button>
			</div>
			</form>
			{searchResults!=null&&<div className="mt-4 bg-slate-700 rounded-md divide-y divide-slate-800">
				{searchResults.data.map(result => <div className="px-3 py-2 flex space-x-2" key={`store-search-${result.id}`}>
						<input id={`store-${result.id}`} type="radio" checked={store!=null&&store.id==result.id} onChange={() => setStore(result)}/>
						<label htmlFor={`store-${result.id}`} className="block text-slate-200" to={`/stores/${result.id}`}><span className="block">{result.store_name}</span><span className="block text-xs text-slate-400">{result.shopify_domain}.myshopify.com</span></label>

					</div>)}

			{
				searchResults!=null&&<div className="flex py-1 px-1 justify-between items-center">
					<button onClick={() => changePage(searchResults.current_page-1)} className="text-slate-200 hover:bg-slate-900 px-2 py-2 rounded-md hover:text-white"><ChevronLeftIcon className="w-5 h-5"/></button>
					<span className="text-slate-400">Page {searchResults.current_page} of {searchResults.last_page}</span>
					<button onClick={() => changePage(searchResults.current_page+1)} className="text-slate-200 hover:bg-slate-900 px-2 py-2 rounded-md hover:text-white"><ChevronRightIcon className="w-5 h-5"/></button>
				</div>
			}
			</div>}

		</Modal>
		{
			storeSelectedForRemoval!=null&&<Modal open={storeSelectedForRemoval} close={() => setStoreSelectedForRemoval(null)} title="Remove Access?" primary={{disabled: disabled, action: () => removeAccess(), label: 'Confirm Removal'}} secondary={{label: 'Close', action: () => setStoreSelectedForRemoval(null)}}>
				<p className="text-slate-200 mb-2">Are you sure you want to remove access to <strong className="text-indigo-400">{collection.name}</strong> from <strong className="text-indigo-400">{storeSelectedForRemoval.store_name}</strong>?</p>
				<p className="text-slate-200 mb-2">If this store has products from this collection in their catalog, they will be removed.</p>
				<p className="text-slate-200">Please note that this process may take more than 30 seconds to complete.</p>
			</Modal>
		}
		<Toast message={message} onReset={() => setMessage(false)}/>
	</Layout>
}