import React, {useEffect, useState, useRef} from 'react';
import {
  Link,
  useParams,
  useRouteMatch,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import {useCookies} from 'react-cookie';
import {useAuthRequest} from '../auth.js';
import moment from 'moment';
import {Toast, Label} from '../dsb.js';
import numeral from 'numeral';
import Layout from '../components/layout';
import { BeakerIcon, TrashIcon, BuildingStorefrontIcon,  PencilSquareIcon, ChartBarIcon, UsersIcon, MagnifyingGlassIcon, CheckIcon, XMarkIcon} from '@heroicons/react/24/solid'



export default function Password() {

	const {authFetch} = useAuthRequest();

	const navigate = useNavigate();

	/* Refs */

	const passwordRef = useRef();

	const confirmationRef = useRef();

	const currentPasswordRef = useRef();

	const [message, setMessage] = useState(false);

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);


	const savePassword = async(e) => {
		e.preventDefault();
		const formData = new FormData();

		formData.append('password', passwordRef.current.value);
		formData.append('current_password', currentPasswordRef.current.value);
		formData.append('confirmation', confirmationRef.current.value);

		const response = await authFetch(`/api/admin/changePw`, 'POST', formData);

		const {message, error} = response;

		if (message) {
			setMessage(message);
		}

		if (error) {
			setMessage(error);
		}

		return false;
	}

	

	return <Layout current="stores">
		
				<div>
					<div className="md:flex justify-between items-center">
						<h3 className="font-header text-white text-2xl md:text-4xl font-bold pt-4 mb-3 truncate">User Settings</h3>
					</div>
					<div className="mt-4 bg-slate-800 rounded-md w-full max-w-xl overflow-hidden">
					<div className="border-b border-slate-700 px-4 py-2">
						<h3 className="text-slate-200 font-bold text-lg">Change Password</h3>
					</div>
					<form onSubmit={savePassword}>
					<div className="px-4 py-4 space-y-2">
					<div>
							<p className="text-slate-400 text-sm mb-1">Passwords must be at least 8 characters in length, contain at least 1 uppercase letter, 1 lowercase letter, 1 digit, 1 symbol.</p>
						</div>
						<div>
						<Label>Current Password</Label>
						<input ref={currentPasswordRef} type="password" className="bg-white px-4 py-3 rounded-md block w-full text-sm" placeholder="" />
						</div>
						<div>
						<Label>New Password <button type="button" className="font-normal text-xs text-indigo-400" onClick={() => setShowPassword(!showPassword)}>{showPassword?'Hide':'Show'}</button></Label>
							<input ref={passwordRef} type={showPassword?'text':'password'} className="bg-white px-4 py-3 rounded-md block w-full text-sm" placeholder=""/>
						</div>
						<div>
						<Label>Confirm Password  <button type="button" className="font-normal text-xs text-indigo-400" onClick={() => setShowConfirmation(!showConfirmation)}>{showConfirmation?'Hide':'Show'}</button></Label>
						<input ref={confirmationRef} type={showConfirmation?'text':'password'} className="bg-white px-4 py-3 rounded-md block w-full text-sm" placeholder="" />
						</div>

						<div>
							<button type="submit" className="bg-indigo-600 px-4 py-2 font-bold text-white text-sm rounded-md mt-2">Save Changes</button>
						</div>
					</div>
					</form>
					</div>
				</div>
				
			<Toast message={message} onReset={() => setMessage(false)} duration={3500}/>
	</Layout>;
}