import React, {useEffect, useState, useRef} from 'react';
import {
  Link,
  useParams,
  useRouteMatch,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import {useCookies} from 'react-cookie';
import {useAuthRequest} from '../auth.js';
import moment from 'moment';
import {PlanBadge, Paginator, OrderStatus, MenuButton, Modal, Toast, Button, Card, Label, Select} from '../dsb.js';
import numeral from 'numeral';
import Layout from '../components/layout';
import { BeakerIcon, TrashIcon, BuildingStorefrontIcon,  PencilSquareIcon, ChartBarIcon, UsersIcon, MagnifyingGlassIcon, CheckIcon, XMarkIcon} from '@heroicons/react/24/solid'

const statuses = [
	{status: 'pending', label: 'Pending', description: 'Orders that are awaiting payment'},
	{status: 'paid', label: 'Paid', description: 'Orders that have been paid and are awaiting fulfillment'},
	{status: 'fulfilled', label: 'Fulfilled', description: 'Orders that have been shipped'},
	{status: 'cancelled', label: 'Cancelled', description: 'Orders that have been cancelled by the seller'},
	{status: 'archived', label: 'Archived', description: 'Orders that are older than 30 days'}
];

export default function Orders() {

	const [searchParams, setSearchParams] = useSearchParams();

	const {authFetch} = useAuthRequest();

	const navigate = useNavigate();

	const [orders, setOrders] = useState(null);

	const searchRef = useRef();

	const status = searchParams.has('status')?searchParams.get('status'):'';

	const hasQuery = searchParams.has('q');

	const loadOrders = async(page=1) => {

		const status = searchParams.has('status')?searchParams.get('status'):'';

		const q = searchParams.has('q')?searchParams.get('q'):false;

		const endpoint = q?`/api/admin/orders?q=${q}&page=${page}&status=search`:`/api/admin/orders?status=${status}&page=${page}`;

		const response = await authFetch(endpoint);

		const {orders} = response;

		setOrders(orders);
	}

	const onSubmit = (e) => {
		e.preventDefault();

		const q = searchRef.current.value;

		navigate(`/orders?q=${escape(q)}`);

		return false;
	}

	useEffect(() => {
		loadOrders();
		if (searchRef.current && !searchParams.has('q')) {
			searchRef.current.value = '';
		}

		if (!searchParams.has('status') && !searchParams.has('q')) {
			navigate('/orders?status=pending');
		}
	}, [status, searchParams]);

	const currentStatus = statuses.find(tab => (tab.status == status));


	return <Layout current={`orders`}>
		<>
			<h3 className="text-white text-2xl md:text-4xl font-bold pt-4 mb-4">Orders</h3>
			{currentStatus&&<div className="my-4">
					<p className="text-sm text-slate-400">{currentStatus.description}</p>
				</div>}
				<div className="mb-4">
					<form onSubmit={onSubmit}>
						<div className="flex shadow-lg">
							<input defaultValue={searchParams.get('q')} ref={searchRef} type="text" className="text-sm bg-white px-4 py-3 rounded-l-md w-full block" placeholder="Search by email, store name, shopify domain, ID"/>
							<button className="bg-indigo-600 font-bold text-sm px-6 py-3 rounded-r-md"><MagnifyingGlassIcon className="text-white w-5 h-5"/></button>
						</div>
					</form>
				</div>
				<div className="space-x-3 items-center hidden md:flex">
					{
						hasQuery&&<span className="px-4 py-2 text-sm inline-block flex items-center bg-slate-800 shadow-xl text-white border border-white rounded-md">Search: {searchParams.get('q')} <Link to={`/orders?status=pending`} className="ml-2"><XMarkIcon className="w-5 h-5"/></Link></span>
					}
					{
						statuses.map(tab => <Link className={`${tab.status==status?'bg-slate-800 shadow-xl text-white border border-white rounded-md':'text-slate-300 border border-transparent'} px-4 py-2 text-sm inline-block`} key={`tab-${tab.status}`} to={`?status=${tab.status}`}>{tab.label}</Link>)
					}
				</div>
				{
					hasQuery?<span className="px-4 py-2 text-sm md:hidden flex items-center justify-between bg-slate-800 shadow-xl text-white border border-white rounded-md">Search: {searchParams.get('q')} <Link to={`/orders?status=pending`} className="ml-2"><XMarkIcon className="w-5 h-5"/></Link></span>:
				
				<div className="md:hidden">
					
					<Select className="md:w-auto md:block-inline block w-full" options={statuses.map(status => ({label: status.label, value: status.status}))} value={searchParams.get('status')} onSelect={(e) => navigate(`/orders?status=${e.target.value}`)}/>
			
				</div>}


				
				<div className="bg-gray-800 rounded-md mt-4 overflow-auto">
					{orders!=null&&<table className="md:w-full w-[1000px]">
					<thead className="text-left border-b border-slate-700 text-slate-300 text-xs uppercase">
						<tr>
							<th className=" px-4 py-3">ID</th>
							<th className="px-4 py-3">Customer</th>
							<th className="px-4 py-3">Status</th>
							<th className="px-4 py-3">Store Name</th>
							<th className="px-4 py-3">Cost</th>
							<th className="px-4 py-3">Ordered</th>
							<th className="px-4 py-3">Action</th>
							
						</tr>
					</thead>
					<tbody className="divide-y divide-slate-700 w-full  text-sm text-gray-200 border-b border-slate-700">
						{
							orders.data.map(order => <tr key={`order-${order.id}`}>
									<td className="px-4 py-3"><Link className="hover:text-cyan-400 text-cyan-300"  to={`/orders/${order.id}`}>{order.id}</Link></td>
									<td className="px-4 py-3"><Link className="hover:text-cyan-400 text-cyan-300" to={`/orders/${order.id}`}>{order.redacted==1?'Redacted':`${order.first_name} ${order.last_name}`}</Link></td>
									<td className="px-4 py-3"><OrderStatus order={order}/></td>
									<td className="px-4 py-3"><Link className="hover:text-cyan-400 text-cyan-300" to={`/stores/${order.user_id}`}>{order.store.store_name}</Link></td>
									<td className="px-4 py-3"><span>{numeral(order.total_cost).format('$0,0.00')}</span></td>
									<td className="px-4 py-3"><span>{moment(order.created_at).fromNow()}</span></td>
									<td className="px-4 py-3"><Link className="hover:text-cyan-400 text-cyan-300" to={`/orders/${order.id}`}>View Order</Link></td>
								</tr>)
						}
						{
							orders.data.length==0&&<tr className="">
								<td className="px-4 py-20 text-center" colspan={7}>
									<p className="text-sm text-slate-400">No orders found..</p>
								</td>
							</tr>
						}
					</tbody>
					</table>}

					
				</div>
				{orders!=null&&<div className="py-2">
						<Paginator data={orders} changePage={loadOrders}/>
					</div>}


		</>
	</Layout>
}