import React, {useEffect, useState, useRef} from 'react';
import {
  Link,
  useParams,
  useRouteMatch,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import {useCookies} from 'react-cookie';
import {useAuthRequest} from '../auth.js';
import moment from 'moment';
import {PlanBadge, Paginator, Select} from '../dsb.js';
import Layout from '../components/layout';
import numeral from 'numeral';
import { BeakerIcon, BuildingStorefrontIcon, ChartBarIcon, UsersIcon, MagnifyingGlassIcon, CheckIcon, XMarkIcon} from '@heroicons/react/24/solid'

export default function Collections() {

	const {authFetch} = useAuthRequest();

	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();

	const queryRef = useRef();

	const [collections, setCollections] = useState(null);

	const loadCollections = async() => {

		const page = searchParams.has('page')?`page=${searchParams.get('page')}&`:'';

		const q = searchParams.has('q')?`q=${searchParams.get('q')}`:'';

		const response = await authFetch(`/api/admin/collections?${page}${q}`);

		const {collections} = response;

		setCollections(collections);

		window.scrollTo(0,0);
	}

	const submitSearch = (e) => {
		e.preventDefault();
		const q = queryRef.current.value;

		if (q == '') {
			navigate('/collections');
		} else {
			navigate(`/collections?q=${q}&page=1`);
		}

		return false;
	}

	const changePage = (page) => {
		const url = `/collections?${searchParams.has('q')?`q=${searchParams.get('q')}&`:''}page=${page}`;

		navigate(url);
	}

	useEffect(() => {
		loadCollections();
	}, [searchParams]);

	return <Layout current="collections">
		<>
				
				<h3 className="text-white text-4xl font-bold mt-4 mb-4">Collections</h3>
				<div className="mb-4">
					<form onSubmit={submitSearch}>
						<div className="flex shadow-lg mb-4">
							<input ref={queryRef} defaultValue={searchParams.get('q')} type="text" className="text-sm bg-white px-4 py-3 rounded-l-md w-full block" placeholder="Search by collection name or slug"/>
							<button className="bg-indigo-600 font-bold text-sm px-6 py-3 rounded-r-md"><MagnifyingGlassIcon className="text-white w-5 h-5"/></button>
						</div>
						
					</form>
				</div>
				<div className="bg-gray-800 rounded-md overflow-x-auto">
					{collections!=null&&<table className="w-full">
					<thead className="text-left border-b border-slate-700 text-slate-300 text-xs uppercase">
							<tr>
								<th className="px-4 py-2 text-left">ID</th>
								<th className="px-4 py-2 text-left">Collection Name</th>
								<th className="px-4 py-2 text-left">Slug</th>
								<th className="px-4 py-2 text-left">Status</th>
								<th className="px-4 py-2 text-right">Actions</th>
							</tr>
						</thead>
						<tbody className="divide-y divide-slate-700 text-slate-400 text-sm">
							{
								collections.data.map(collection => <tr key={`collection-${collection.id}`}>
										<td className="px-4 py-2 text-left">
											<Link to={`/collections/${collection.id}`}>{collection.id}</Link>
										</td>
										<td className="px-4 py-2 text-left">
											<Link to={`/collections/${collection.id}`}>{collection.name}</Link>
										</td>
										<td className="px-4 py-2 text-left">
											<Link to={`/collections/${collection.id}`}>{collection.slug}</Link>
										</td>
										<td className="px-4 py-2 text-left">
											{
												collection.exclusive==1?<span className="bg-amber-400 text-black px-3 py-1 uppercase font-bold text-xs rounded-full w-24 inline-block text-center">Exclusive</span>:<span  className="bg-slate-600 w-24 inline-block text-center text-white px-3 py-1 uppercase font-bold text-xs rounded-full">Open</span>
											}
										</td>
										<td className="px-4 py-2 text-right">
											<Link className="text-cyan-400" to={`/collections/${collection.id}`}>Manage</Link>
										</td>
									</tr>)
							}
						</tbody>
					</table>}
				</div>
				{collections!=null&&<div className="my-3">
					<Paginator data={collections} changePage={changePage}/>
				</div>}
		</>
	</Layout>
}