import React, {useEffect, useState, useRef} from 'react';
import {
  Link,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import {useAuthRequest} from '../auth.js';
import moment from 'moment';
import {PlanBadge, Paginator, Select, Modal, Label, Toast} from '../dsb.js';
import Layout from '../components/layout';
import {  MagnifyingGlassIcon, CheckIcon, XMarkIcon, PlusIcon} from '@heroicons/react/24/solid'

export default function AdminUsers() {

	const {authFetch} = useAuthRequest();

	const [searchParams, setSearchParams] = useSearchParams();

	const navigate = useNavigate();

	const queryRef = useRef();

	const [users, setUsers] = useState(null);

	const [modal, setModal] = useState(false);

	const [message, setMessage] = useState(false);

	const [disabled, setDisabled] = useState(false);

	const [email, setEmail] = useState('');

	const [name, setName] = useState('');

	const [role, setRole] = useState('')

	const submitSearch = (e) => {
		e.preventDefault();

		navigate(`/users?q=${escape(queryRef.current.value)}`);

		return false;
	}

	const changePage = (page) => {
		if (searchParams.has('q') && searchParams.get('q').length > 0) {
			navigate(`/users?q=${searchParams.get('q')}&page=${page}`)
		}

		navigate(`/users?page=${page}`);
	}

	const loadUsers = async() => {
		const page = searchParams.has('page')?searchParams.get('page'):'1';
		const q = searchParams.has('q')&&searchParams.get('q').length>0?`&q=${searchParams.get('q')}`:'';
		const response = await authFetch(`/api/admin/users?page=${page}${q}`);

		const {users} = response;

		setUsers(users);
	}

	const createNewAdmin = async() => {

		const formData = new FormData();

		formData.append('name', name);
		formData.append('email', email);
		formData.append('role', role);

		setDisabled(true);

		const response = await authFetch(`/api/admin/users`, 'POST', formData);

		const {message, error, user} = response;

		if (error) {
			setMessage(error);
		}

		if (message) {
			setMessage(message);
			setModal(false);
		}

		if (user) {
			setTimeout(() => {
				navigate(`/users/${user.id}`);
			}, 500);
		}

		setDisabled(false);
	}

	useEffect(() => {
		loadUsers();

		if (!searchParams.has('q') && queryRef.current) {
			queryRef.current.value = '';
		}
	}, [searchParams]);

	return <Layout current='users'>
		<div className="max-w-4xl">
				<div className="flex items-center justify-between">
					<h3 className="text-white text-4xl font-bold mt-4 mb-4">Administrative Users</h3>
					<button onClick={() => setModal(true)} className="flex shadow-lg items-center hover:bg-indigo-700 space-x-2 font-bold text-white bg-indigo-600 text-sm rounded-md px-4 py-2"><PlusIcon className="w-5 h-5"/><span>Create Admin</span></button>
				</div>
				<div className="mb-4">
					<form onSubmit={submitSearch}>
						<div className="flex shadow-lg mb-4">
							<input ref={queryRef} type="text" className="text-sm bg-white px-4 py-3 rounded-l-md w-full block" placeholder="Search by email, store name, shopify domain, ID"/>
							<button className="bg-indigo-600 font-bold text-sm px-6 py-3 rounded-r-md"><MagnifyingGlassIcon className="text-white w-5 h-5"/></button>
						</div>
						
					</form>
					{
						(searchParams.has('q')&&searchParams.get('q').length>0)&&<button className=" text-slate-200 text-sm hover:text-cyan-400" onClick={() => navigate('/users')}>Clear Search</button>
					}
				</div>
				<div className="bg-gray-800 rounded-md">
					<table className="w-full">
					<thead className="text-left border-b border-slate-700 text-slate-300 text-xs uppercase">
						<tr>
							<th className=" px-4 py-3">ID</th>
							<th className="truncate px-4 py-3">Store Name</th>
							<th className="px-4 py-3">Email</th>
							<th>Created</th>
							<th>Last Login</th>
							<th className="px-4 py-3">Action</th>
							
						</tr>
					</thead>
					<tbody className="divide-y divide-slate-700">
					{
						users!=null&&users.data.map(user => <tr key={`store-${user.id}`} >
								<td className=" px-4 py-3"><Link className="text-indigo-300 text-sm" to={`/users/${user.id}`}>{user.id}</Link></td>
								<td className="truncate px-4 py-3"><Link className="text-indigo-300 text-sm" to={`/users/${user.id}`}>{user.name}</Link></td>
								<td className="truncate max-w-xs px-4 py-3"><a href={`mailto:${user.email}`} className="text-indigo-300 text-sm">{user.email}</a></td>
								
								<td><span className="text-gray-400 text-sm">{moment(user.created_at).fromNow()}</span></td>
								<td><span className="text-gray-400 text-sm">{moment(user.last_login_at).fromNow()}</span></td>
								<td className="px-4 py-3"><Link className="text-indigo-300 text-sm" to={`/users/${user.id}`}>View User</Link></td>
							</tr>)
					}
					{
						users!=null&&users.data.length==0&&<tr>
							<td colspan={8}>
								<div className="py-32 px-4 text-center">
									<p className="text-sm text-white">No admin users found according to your search parameters.</p>
								</div>	
							</td>
						</tr>
					}
					</tbody>
					</table>
					{users!=null&&<div className="px-4 py-4 border-t border-slate-700">
						<Paginator data={users} changePage={changePage}/>
					</div>}

				</div>
		</div>
		<Modal open={modal} close={() => setModal(false)} title="Create New Admin" primary={{label: 'Create User', disabled: (disabled||role==''||email==''||name==''), action: () => createNewAdmin()}} secondary={{label: 'Close' , action: () => setModal(false)}}>
			<div className="space-y-2">
				<div>
					<Label>Email</Label>
					<input type="email" placeholder="you@dropshipbeauty.app" className="rounded-md bg-slate-700 text-slate-200 px-4 py-3 w-full block text-sm" name="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
				</div>
				<div>
					<Label>Full Name</Label>
					<input type="text" name="name" className="rounded-md bg-slate-700 text-slate-200 px-4 py-3 w-full block text-sm" value={name} onChange={(e) => setName(e.target.value)} placeholder="Droppy McShipperson"/>
				</div>
				<div>
					<Label>Role</Label>
					<p className="text-xs text-slate-400 mb-1">You can add and modify permissions afterwards.</p>
					<select className="border-slate-700 text-sm border px-4 py-3 bg-slate-700 text-slate-200 rounded-md w-full" value={role} onChange={(e) => setRole(e.target.value)}>
						<option value="">--Select role</option>
						<option value="basic">Basic Privileges</option>
						<option value="stores">Store Admin</option>
						<option value="orders">Orders Specialist</option>
						<option value="admin">Super Admin</option>
					</select>
				</div>
			</div>
		</Modal>
		<Toast message={message} onReset={() => setMessage(false)}/>
	</Layout>
}