import React, {useEffect, useState, useRef} from 'react';
import {
  Link,
  useParams,
  useRouteMatch,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import {useCookies} from 'react-cookie';
import {useAuthRequest} from '../auth.js';
import moment from 'moment';
import {PlanBadge, Paginator, OrderStatus, variantToString, MenuButton, Modal, Toast, Button, Card, Label, Select} from '../dsb.js';
import numeral from 'numeral';
import Layout from '../components/layout';
import { BeakerIcon, TrashIcon, LinkIcon, BuildingStorefrontIcon, ShoppingBagIcon, PencilSquareIcon, ChartBarIcon, UsersIcon, MagnifyingGlassIcon, CheckIcon, XMarkIcon} from '@heroicons/react/24/solid'

const statuses = [
	{status: 'pending', label: 'Pending', description: 'Orders that are awaiting payment'},
	{status: 'paid', label: 'Paid', description: 'Orders that have been paid and are awaiting fulfillment'},
	{status: 'fulfilled', label: 'Fulfilled', description: 'Orders that have been shipped'},
	{status: 'cancelled', label: 'Cancelled', description: 'Orders that have been cancelled by the seller'},
	{status: 'archived', label: 'Archived', description: 'Orders that are older than 30 days'}
];

export default function Order() {

	const {authFetch} = useAuthRequest();

	const trackingRef = useRef();

	const { id } = useParams();

	const navigate = useNavigate();

	const [order, setOrder] = useState(null);

	const [trackingModal, setTrackingModal] = useState(false);

	const [refundModal, setRefundModal] = useState(false);

	const [disabled, setDisabled] = useState(false);

	const [message, setMessage] = useState(false);

	const [carrier, setCarrier] = useState('');

	const addTracking = async() => {
		setDisabled(true);
		const formData = new FormData();

		formData.append('tracking_number', trackingRef.current.value);
		if (trackingRef.current.value.length < 20) {
			formData.append('carrier', 'fedex');
		} else {
			formData.append('carrier', 'usps');
		}

		const response = await authFetch(`/api/admin/orders/${id}/tracking`, 'POST', formData);

		const {message, error} = response;

		if (message) {
			setMessage(message);
			loadOrder();
			setTrackingModal(false);
		}

		if (error) {
			setMessage(error);
		}

		setDisabled(false);
	}

	const issueRefund = async() => {
		setDisabled(true);
		

		const response = await authFetch(`/api/admin/orders/${id}/refund`, 'POST');

		const {message, error} = response;

		if (message) {
			setMessage(message);
			loadOrder();
			setRefundModal(false);
		}

		if (error) {
			setMessage(error);
		}

		setDisabled(false);
	}

	const loadOrder = async() => {
		const response = await authFetch(`/api/admin/orders/${id}`);

		const { order } = response;

		if (order) {
			setOrder(order);
		}
	}

	useEffect(() => {
		loadOrder();
	}, []);

	return <Layout current={`orders`}>
		{order!=null&&<>
			<div className="flex justify-between items-center">
				<h3 className="text-white text-4xl font-bold pt-4 mb-4">Order <Link className="hidden md:inline text-cyan-400 hover:text-indigo-400" to={`/orders/${order.id}`}>{order.id}</Link> &middot; #{order.number}</h3>
				{order.refund_id==null&&order.charge_id!=null&&<button onClick={() => setRefundModal(true)} className="hover:bg-rose-500 bg-slate-600 text-white shadow-xl rounded-md px-6 py-2 text-sm">Issue Refund</button>}
			</div>
			<div className="mb-4 flex space-x-4"><OrderStatus order={order}/>
			</div>
			<div className="text-slate-200 text-sm space-x-4">

				<Link className="text-cyan-400 hover:text-indigo-400" to={`/stores/${order.user_id}`}>{order.store.store_name}</Link>
				<a className="text-cyan-400 hover:text-indigo-400" href={`mailto:{order.store.email}`}>{order.store.email}</a>
				{order.charge_id!=null&&<a className="text-cyan-400 hover:text-indigo-400" href={`https://dashboard.stripe.com/payments/${order.charge_id}`}>View Charge</a>}
			</div>
			<div className="xl:grid grid-cols-9 gap-4">
			<div className=" col-span-2 space-y-4">
			<div className="bg-gray-800 rounded-md mt-4">
				<div className="px-4 py-3">
					<h3 className="font-bold text-slate-200 mb-2">Customer Details</h3>
					{
						order.redacted==1?<div>
							<p className="text-sm text-slate-200">This order is older than 60 days, and the customer information has been redacted.</p>
						</div>:<div className="text-sm text-slate-300">
							<p className="mb-2"><a className="text-cyan-400 hover:text-indigo-400" href={`mailto:${order.email}`}>{order.email}</a></p>
							<p>{order.first_name} {order.last_name}</p>
							<p>{order.address1}</p>
							{order.address2!=null&&<p>{order.address2}</p>}
							<p>{order.city}, {order.state} {order.zip}</p>
						</div>
					}
				</div>
			</div>
			<div className="bg-gray-800 rounded-md mt-4">
				<div className="px-4 py-3">
					<h3 className="font-bold text-slate-200 mb-2">Tracking Details</h3>
					{
						order.tracking_number!=null?<><p className="mb-4 flex items-center space-x-2">
							<LinkIcon className="w-4 h-4 block text-slate-300"/>
							{
								order.tracking_number.length==20?<a href={`https://tools.usps.com/go/TrackConfirmAction?tLabels=${order.tracking_number}`} className="text-cyan-400 text-sm hover:text-indigo-400">{order.tracking_number}</a>:<a className="text-cyan-400 text-sm hover:text-indigo-400" href={`https://www.fedex.com/fedextrack/?trknbr=${order.tracking_number}`}>{order.tracking_number}</a>
							}

						</p>
							<button onClick={() => setTrackingModal(true)} className="hover:bg-indigo-700 bg-indigo-600 px-4 py-3 text-white rounded-md w-full block text-sm font-bold">Update Tracking</button>
						</>:<><p className="text-sm text-slate-300 mb-4">This order does not have tracking assigned.</p>
							<button onClick={() => setTrackingModal(true)} className="hover:bg-indigo-700 bg-indigo-600 px-4 py-3 text-white rounded-md w-full block text-sm font-bold">Add Tracking</button>
						</>
					}

				</div>
			</div>
			</div>

			<div className="md:hidden">
				<h3 className="text-white text-2xl mt-4 font-bold">Order Items</h3>
			</div>

			<div className="col-span-7">

			<div className="bg-gray-800 rounded-md mt-4 overflow-auto mb-8 md:mb-0">
				<table className="w-[900px] md:w-full">
				<thead className="text-left border-b border-slate-700 text-slate-300 text-xs uppercase">
					<tr>
						<th className="px-4 py-3">Product</th>
						<th className="px-4 py-3">Quantity</th>
						<th className="px-4 py-3 text-right">Line Cost</th>
						<th className="px-4 py-3 text-right">Line Price</th>
						
					</tr>
				</thead>
				<tbody className="divide-y divide-slate-700 w-full  text-sm text-gray-200 border-b border-slate-700">
					{
						order.items.map(item => {

							if (item.variant == null) {
								return <tr key={`order-item-${item.id}`}>
									<td className="px-4 py-3">
									<div className="flex space-x-4">
										<div className="bg-slate-600 w-24 h-24 rounded-md flex items-center justify-center"><ShoppingBagIcon className="w-5 h-5 text-slate-700"/></div>
										<div className="">
											<h5 className="font-bold block text-base mb-1">Item Missing</h5>
											<span className="block text-xs w-32 bg-slate-700"><strong>&nbsp;</strong></span>
										</div>
									</div>
									</td>
									<td className="align-top py-3 px-4">
										<span className="block">{item.quantity}</span>
									</td>
									<td className="align-top py-3 px-4 text-right">
										<span className="block  font-bold">{numeral(item.line_cost).format('$0,0.00')}</span>
									</td>
									<td className="align-top py-3 px-4 text-right">
										<span className="block  font-bold">{numeral(item.line_total).format('$0,0.00')}</span>
									</td>
								</tr>
							}

							return <tr key={`order-item-${item.id}`}>
								<td className="px-4 py-3">
									<div className="flex space-x-4">
										{item.variant.product_import.product.image?<img src={item.variant.product_import.product.image.src} className="rounded-md object-contain w-24 h-24 bg-white"/>:<div className="bg-slate-200 w-24 h-24 rounded-md flex items-center justify-center"><ShoppingBagIcon className="w-5 h-5"/></div>}
										<div className="">
											<Link to={`/products/${item.variant.product_import.product_id}`} className="font-bold block text-base mb-1 hover:text-cyan-400">{item.variant.product_import.product.title}</Link>
											<span className="block text-xs"><strong>{item.variant.sku}</strong> &middot; {variantToString(item.variant)}</span>
										</div>
									</div>
								</td>
								<td className="align-top py-3 px-4">
									<span className="block">{item.quantity}</span>
								</td>
								<td className="align-top py-3 px-4 text-right">
									<span className="block  font-bold">{numeral(item.line_cost).format('$0,0.00')}</span>
								</td>
								<td className="align-top py-3 px-4 text-right">
									<span className="block  font-bold">{numeral(item.line_total).format('$0,0.00')}</span>
								</td>
							</tr>})
					}
					<tr>
						<td className="px-4 py-3">
							Subtotal
						</td>
						<td className="px-4 py-3">
							
						</td>
						<td className="px-4 py-3 text-right">
							<span className="block font-bold">{numeral(order.total_cost).format('$0,0.00')}</span>
						</td>
						<td className="px-4 py-3 text-right">
							<span className="block font-bold">{numeral(order.total_price).format('$0,0.00')}</span>
						</td>
					</tr>
					<tr>
						<td className="px-4 py-3">
							{order.shipping.shipping_name}
						</td>
						<td className="px-4 py-3">
							
						</td>
						<td className="px-4 py-3 text-right">
							<span className="block font-bold">{numeral(order.shipping.price).format('$0,0.00')}</span>
						</td>
						<td className="px-4 py-3 text-right">
							<span className="block font-bold">{numeral(order.shipping_amount).format('$0,0.00')}</span>
						</td>
					</tr>
					<tr>
						<td className="px-4 py-3">
							<strong className="text-lg text-white">Total</strong>
						</td>
						<td className="px-4 py-3">
							
						</td>
						<td className="px-4 py-3 text-right">
							<span className="text-lg block text-white font-bold">{numeral(order.total_cost+order.shipping.price).format('$0,0.00')}</span>
						</td>
						<td className="px-4 py-3 text-right">
							<span className="text-lg text-white block font-bold">{numeral(order.total_price+order.shipping_amount).format('$0,0.00')}</span>
						</td>
					</tr>
				</tbody>
				</table>
				</div>
				</div>
				</div>
				{order.charge_id!=null&&<Modal title="Issue Refund?" open={refundModal} close={() => setRefundModal(false)} primary={{label: 'Confirm Refund', action: () => issueRefund(), disabled: disabled}} secondary={{label: 'Close', action: () => setRefundModal(false)}}>
					<p className="text-sm text-slate-300 mb-3">You are about to issue a full refund for order <strong className="text-white font-bold">#{order.number}</strong> for <strong className="font-bold text-white">{numeral(order.total_cost+order.shipping.price-order.discount_amount).format('$0,0.00')}</strong>.</p>
					<p className="text-sm text-slate-300">You may issue partial refunds in the <a href={`https://dashboard.stripe.com/payments/${order.charge_id}`} target="_blank" className="text-cyan-400">Stripe dashboard</a></p>
				</Modal>}
				<Modal title="Add Tracking" open={trackingModal} close={() => setTrackingModal(false)} primary={{label: 'Add Tracking', action: () => addTracking(), disabled: disabled}} secondary={{label: 'Close', action: () => setTrackingModal(false)}}>
					<>
						<Label>Tracking Number</Label>
						<input ref={trackingRef} type="text" className="mt-1 rounded-md w-full block px-4 py-3 border bg-white text-slate-900" defaultValue={(order.tracking_number!=null?order.tracking_number:'')}/>
					</>
				</Modal>
				<Toast message={message} onReset={() => setMessage(false)}/>
		</>}
	</Layout>

}