import logo from './logo.svg';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { AuthProvider } from './auth.js';
import './App.css';
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import Stores from './pages/stores';
import Store from './pages/store';
import Orders from './pages/orders';
import Order from './pages/order';
import StoreRequests from './pages/storeRequests';
import StoreRequest from './pages/storeRequest';
import CustomBrandingRequests from './pages/brandingRequests';
import CustomBrandingRequest from './pages/brandingRequest';
import Products from './pages/products';
import Product from './pages/product';
import AdminUsers from './pages/users';
import AdminUser from './pages/user';
import ResetPassword from './pages/reset';
import Password from './pages/password';
import Collections from './pages/collections';
import Collection from './pages/collection';
import Content from './pages/content';
import EditContent from './pages/editContent';

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login/>,
    },
    {
      path: "/login",
      element: <Login />
    },
    {
      path: "/dashboard",
      element: <Dashboard />
    },
    {
      path: "/stores",
      element: <Stores/>
    },
    {
      path: "/stores/:id",
      element: <Store/>
    },
    {
      path: "/orders",
      element: <Orders/>
    },
    {
      path: '/content',
      element: <Content/>
    },
    {
      path: '/content/:id',
      element: <EditContent/>
    },
    {
      path: '/new-content',
      element: <EditContent/>
    },
    {
      path: "/orders/:id",
      element: <Order />
    },
    {
      path: "/custom-branding-requests",
      element: <CustomBrandingRequests/>
    },
    {
      path: "/custom-branding-requests/:id",
      element: <CustomBrandingRequest/>
    },
    {
      path: "/setups/:type",
      element: <StoreRequests/>
    },
    {
      path: "/setup/:id",
      element: <StoreRequest/>
    },
    {
      path: "/products",
      element: <Products/>
    },
    {
      path: "/products/:id",
      element: <Product/>
    },
    {
      path: "/users",
      element: <AdminUsers/>
    },
    {
      path: "/users/:id",
      element: <AdminUser/>
    },
    {
      path: "/reset",
      element: <ResetPassword/>
    },
    {
      path: '/password',
      element: <Password/>
    },
    {
      path: '/collections',
      element: <Collections/>
    },
    {
      path: '/collections/:id',
      element: <Collection/>
    }

  ]);

  return (
    <AuthProvider>
     <RouterProvider router={router} />
    </AuthProvider>
  );
  
}

export default App;
