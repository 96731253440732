import React, {useEffect, useState, useRef} from 'react';
import {
  Link,
  useParams,
  useRouteMatch,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import {useCookies} from 'react-cookie';
import {useAuthRequest} from '../auth.js';
import moment from 'moment';
import {PlanBadge, Paginator, OrderStatus, MenuButton, Modal, Toast, Button, Card, Label, Select} from '../dsb.js';
import numeral from 'numeral';
import Layout from '../components/layout';
import { BeakerIcon, TrashIcon, BuildingStorefrontIcon,  PencilSquareIcon, ChartBarIcon, UsersIcon, MagnifyingGlassIcon, CheckIcon, XMarkIcon} from '@heroicons/react/24/solid'


export default function Content() {

	

	const {authFetch} = useAuthRequest();

	const navigate = useNavigate();

	const [announcements, setAnnouncements] = useState(null);

	const loadAnnouncements = async(page=1) => {
		const response = await authFetch(`/api/admin/announcements?page=${page}`, 'GET');

		const {announcements} = response;

		setAnnouncements(announcements);
	}

	useEffect(() => {
		loadAnnouncements();
	}, [])


	return <Layout current={`content`}>
		<>
			<h3 className="text-white text-2xl md:text-4xl font-bold pt-4 mb-4">Announcements</h3>

			{
				announcements!=null&&<>
					<div className="bg-gray-800 rounded-md mt-4 overflow-auto">
					<table className="md:w-full w-[1000px]">
					<thead className="text-left border-b border-slate-700 text-slate-300 text-xs uppercase">
						<tr>
							<th className=" px-4 py-3">ID</th>
							<th className="px-4 py-3">Title</th>
							<th className="px-4 py-3">Type</th>
							<th className="px-4 py-3">Action</th>
							
						</tr>
					</thead>
					<tbody className="divide-y divide-slate-700 w-full  text-sm text-gray-200 border-b border-slate-700">
						{
							announcements.data.map(announcement => <tr key={`order-${announcement.id}`}>
									<td className="px-4 py-3"><Link className="hover:text-cyan-400 text-cyan-300"  to={`/content/${announcement.id}`}>{announcement.id}</Link></td>
									<td className="px-4 py-3"><Link className="hover:text-cyan-400 text-cyan-300" to={`/content/${announcement.id}`}>{announcement.title_txt}</Link></td>
									<td className="px-4 py-3">{announcement.timed==1?'Timed':'Published'}</td>
									
									<td className="px-4 py-3"><Link className="hover:text-cyan-400 text-cyan-300" to={`/content/${announcement.id}`}>Edit Content</Link></td>
								</tr>)
						}
						{
							announcements.data.length==0&&<tr className="">
								<td className="px-4 py-20 text-center" colspan={7}>
									<p className="text-sm text-slate-400">No announcements found..</p>
								</td>
							</tr>
						}
					</tbody>
					</table>

					
				</div>
				{announcements!=null&&<div className="py-2">
						<Paginator data={announcements} changePage={loadAnnouncements}/>
					</div>}


				</>
			}
		</>
	</Layout>
}