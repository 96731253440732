import React, {useEffect, useState} from 'react';
import {
  Link,
  useParams,
  useRouteMatch,
  useNavigate
} from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import {MenuButton, Paginator} from '../dsb';
import { Line } from "react-chartjs-2";
import {useAuthRequest} from '../auth.js';
import {useCookies} from 'react-cookie';
import Layout from '../components/layout';
import numeral from 'numeral';
import moment from 'moment';
import { ArrowUpIcon, ArrowDownIcon} from '@heroicons/react/24/solid'


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Dashboard() {

	const {authFetch} = useAuthRequest();

	const navigate = useNavigate();

	const [data, setData] = useState(null);

	const [interval, setInterval] = useState('Month');


	const [data2, setData2] = useState(null);

	const [interval2, setInterval2] = useState('Week');

	const [productChanges, setProductChanges] = useState(null);

	const options = {
	  responsive: true,
	  scales: {
          y: {
          	beginAtZero: true,
          	ticks: {
                beginAtZero: true
            }
          }
      },
	  
	  plugins: {
	    legend: {
	      position: "top"
	    },
	    title: {
	      display: false,
	      text: "Revenue vs. Profit"
	    }
	  }
	};

	const loadChart = async() => {
		const response = await authFetch(`/api/admin/dashboard?interval=${interval[0].toLowerCase()}`);

		const {intervals, profit, revenue} = response;

		const _data = {
		  labels: intervals,
		  datasets: [
		    {
		      label: 'Seller Profit',
		      data: profit,
		      borderColor: 'rgb(99, 200, 99)',
		      backgroundColor: 'rgba(99, 200, 99, .5)',
		    },
		    {
		      label: 'Our Revenue',
		      data: revenue,
		      borderColor: 'rgb(53, 162, 235)',
		      backgroundColor: 'rgba(53, 162, 235, 0.5)',
		    },
		  ],
		};


		setData(_data);
	}

	const loadProductChanges = async(page=1) => {
		const response = await authFetch(`/api/admin/product-changes?page=${page}`);

		const {variant_changes} = response;

		setProductChanges(variant_changes);
	}

	const loadChart2 = async() => {
		const response = await authFetch(`/api/admin/dashboard2?interval=${interval2[0].toLowerCase()}`);

		const {intervals, active, pro, advanced, basic} = response;

		const _data = {
		  labels: intervals,
		  datasets: [
		    {
		      label: 'Active',
		      data: active,
		      borderColor: 'rgb(99, 200, 99)',
		      backgroundColor: 'rgba(99, 200, 99, .5)',
		    },
		    {
		      label: 'Pro',
		      data: pro,
		      borderColor: 'rgb(53, 162, 235)',
		      backgroundColor: 'rgba(53, 162, 235, 0.5)',
		    },
		    {
		      label: 'Advanced',
		      data: advanced,
		      borderColor: 'rgb(255,165,0)',
		      backgroundColor: 'rgba(255,165,0, 0.5)',
		    },
		    {
		      label: 'Basic',
		      data: basic,
		      borderColor: 'rgb(240,128,128)',
		      backgroundColor: 'rgba(240,128,128, 0.5)',
		    },
		  ],
		};


		setData2(_data);
	}

	useEffect(() => {
		loadChart();
	}, [interval]);

	useEffect(() => {
		loadChart2();
	}, [interval2]);

	useEffect(() => {
		loadProductChanges();
	}, []);

	return <Layout current="dashboard">
		<div className="w-full">	
			<div className="py-4">
				<h1 className="text-2xl md:text-4xl font-bold text-white">Dashboard</h1>
			</div>
			<div className="md:grid grid-cols-2 gap-4 mb-4 space-y-4 md:space-y-0 ">
				<div className="bg-slate-800 p-4 rounded-md w-full h-96 pb-16">
				<div className="md:flex justify-between items-center">
					<h3 className="text-slate-200 font-bold mb-4 text-xl">Seller Profit vs. Revenue</h3>
					<div className="flex items-center text-slate-400 text-sm md:space-x-2">
					<span className="hidden md:block">Interval:</span>
					<MenuButton 
						className="mb-4 md:mb-0"
						label={interval}
						items={[
							{label: 'Day', action: () => setInterval('Day')},
							{label: 'Week', action: () => setInterval('Week')},
							{label: 'Month', action: () => setInterval('Month')},
							{label: 'Year', action: () => setInterval('Year')},
						]}/></div>
				</div>
				{data!=null&&<Line options={options} data={data} width="100%" height="50%" options={{ maintainAspectRatio: false }}/>}
				</div>
				<div className="bg-slate-800 p-4 rounded-md max-w-screen overflow-hidden  pb-16 h-96">
				<div className="md:flex justify-between items-center ">
					<h3 className="text-slate-200 font-bold mb-4 text-xl">Total Sellers</h3>
					<div className="flex items-center text-slate-400 text-sm md:space-x-2">
					<span className="hidden md:block">Interval:</span>
					<MenuButton 
						className="mb-4 md:mb-0"
						label={interval2}
						items={[
							{label: 'Day', action: () => setInterval2('Day')},
							{label: 'Week', action: () => setInterval2('Week')},
							{label: 'Month', action: () => setInterval2('Month')},
							{label: 'Year', action: () => setInterval2('Year')},
						]}/></div>
				</div>
				{data2!=null&&<Line options={options} data={data2} width="100%" height="50%" options={{ maintainAspectRatio: false }}/>}
				</div>
			</div>
			<div className="mb-4">
				{
					productChanges!=null?<div className="bg-slate-800 rounded-md w-full">
						<div className="px-4 py-3 border-b border-slate-700">
							<h3 className="text-slate-200 font-bold text-xl">Product Changes</h3>
						</div>
						<div className="w-full overflow-x-auto">
						<table className="table-auto overflow-scroll w-[800px] md:w-full">
							<thead className=" text-left border-b border-slate-700 text-slate-300 text-xs uppercase">
								<tr>
									<th className="px-4 py-3">Product</th>
									<th className="px-4 py-3">Change</th>
									
									<th className="px-4 py-3">Date</th>
								</tr>
							</thead>
							<tbody className="divide-y divide-slate-700 w-full  text-sm text-gray-200 border-b border-slate-700">
								{
									productChanges.data.map(change => <tr className="" key={`change-${change.id}`}>
										<td className="flex items-center px-4 py-3">
											<div className="flex items-center space-x-2">
												 <img src={change.product.image.src} className="w-12 rounded-md"/>
												 <div>
												 	<strong className="block">{change.product.title}</strong>
												 	<span>{change.variant.sku}</span>
												 </div>
											</div>
										</td>
										<td className="px-4 py-3">
											{change.old_price!=null&&change.old_price<change.new_price&&<span><ArrowUpIcon className="w-5 h-5 inline-block mr-2 text-lime-400"/>Price increased from <span className="font-bold text-slate-200">{numeral(change.old_price).format('$0,0.00')}</span> to <span className="font-bold text-lime-400">{numeral(change.new_price).format('$0,0.00')}</span></span>}
											{change.old_price!=null&&change.old_price>change.new_price&&<span><ArrowDownIcon className="w-5 h-5 inline-block mr-2 text-indigo-400"/>Price decreased from <span className="font-bold text-slate-200">{numeral(change.old_price).format('$0,0.00')}</span> to <span className="font-bold text-indigo-400">{numeral(change.new_price).format('$0,0.00')}</span></span>}
											{change.old_count!=null&&change.old_count<change.new_count&&<span><ArrowUpIcon className="w-5 h-5 inline-block mr-2 text-lime-400"/>Inventory increased from <span className="font-bold text-slate-200">{numeral(change.old_count).format('0,0')}</span> to <span className="font-bold text-lime-400">{numeral(change.new_count).format('0,0')}</span></span>}
											{change.old_count!=null&&change.old_count>change.new_count&&<span><ArrowDownIcon className="w-5 h-5 inline-block mr-2 text-indigo-400"/>Inventory decreased from <span className="font-bold text-slate-200">{numeral(change.old_count).format('0,0')}</span> to <span className="font-bold text-indigo-400">{numeral(change.new_count).format('0,0')}</span></span>}

										</td>
										<td>
											<span className="text-sm text-slate-400">{moment(change.created_at).fromNow()}</span>
										</td>
									</tr>)
								}
							</tbody>
						</table>
						</div>
						
					</div>:<div></div>
				}
				{
					productChanges!=null&&<div className="py-4">
							<Paginator data={productChanges} changePage={loadProductChanges}/>
						</div>
				}
			</div>
		</div>
	</Layout>;
}