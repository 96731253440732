import React, {useEffect, useState} from 'react';
import {
  Link,
  useSearchParams,
  useNavigate
} from "react-router-dom";
import {  Toast} from '../dsb.js';
import {useCookies} from 'react-cookie';

export default function ResetPassword() {

	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();

	const [email, setEmail] = useState('');

	const [password, setPassword] = useState('');

	const [confirmation, setConfirmation] = useState('');

	const [toastMessage, setToastMessage] = useState(false);

	const [message, setMessage] = useState('');

	const [error, setError] = useState('');

	const [disabled, setDisabled] = useState(false);

	const [reset, setReset] = useState(null);

	const resetPassword = async(e) => {
		e.preventDefault();

		const formData = new FormData();

  		setDisabled(true);

  		formData.append('id', searchParams.get('id'));
  		formData.append('token', searchParams.get('token'));
  		formData.append('password', password);
  		formData.append('confirm', confirmation);

  		const url = process.env.NODE_ENV=='production'?`https://dropshipbeauty.app/api/admin/resetPassword`:`/api/admin/resetPassword`;


  		const response = await fetch(url, {
  			method: 'POST',
  			body: formData
  		}).then(resp => {

	    	return resp.json();

	    });

	    const {error, message} = response;

	    if (error) {
	    	setToastMessage(error);
	    }

	    if (message) {
	    	setToastMessage(message);
	    	setTimeout(() => {
	    		navigate(`/login`);
	    	}, 1000);
	    }

	    setDisabled(false);

	    return false;
	 }

	 const loadReset = async() => {

  		const url = process.env.NODE_ENV=='production'?`https://dropshipbeauty.app/api/admin/reset`:`/api/admin/reset`;

  		const response = await fetch(url+'?id='+searchParams.get('id')+'&token='+searchParams.get('token')).then(resp => {

	    	return resp.json();

	    });

	    const {reset} = response;

	    if (reset) {
	    	setReset(reset);
	    } else {
	    	navigate('/')
	    }
	 }

	 useEffect(() => {
	 	loadReset();
	 }, [searchParams]);

	 if (reset == null) {
	 	return null;
	 }

	return <main className="bg-gray-800 h-screen">
		
		<div className="container mx-auto py-6 h-full">
			<div className="grid grid-cols-2 h-full">
				<div className="flex items-center justify-center relative">
					<Link to="/"><img src="https://res.cloudinary.com/wigmarket/image/upload/v1680273284/dropship-beauty-logo_200x_2x_h9r4bf_oeynnl_nda6ep.png" className="w-32 absolute top-0 left-0"/></Link>
					<div className="w-full flex items-center justify-center">
						<form onSubmit={resetPassword} className="w-96">
						<div className="w-full space-y-5">
							<div>
								<h3 className="text-xl font-bold text-white">Reset Your Password</h3>
							</div>
							<div>
								<label className="block font-bold text-slate-100 text-xs uppercase">Email</label>
								<input readOnly type="email" name="email" value={reset.user.email} className="text-white border-b border-slate-400 bg-transparent block w-full px-2 py-2" placeholder="you@email.com"/>
							</div>
							<div>
								<label className="block font-bold text-slate-100 text-xs uppercase">Password</label>
								<input type="password" name="password" className="text-white border-b border-slate-400 bg-transparent block w-full px-2 py-2" value={password} onChange={(e) => setPassword(e.target.value)}/>
							</div>
							<div>
								<label className="block font-bold text-slate-100 text-xs uppercase">Confirm Password</label>
								<input type="password" name="confirmation" className="text-white border-b border-slate-400 bg-transparent block w-full px-2 py-2" value={confirmation} onChange={(e) => setConfirmation(e.target.value)}/>
							</div>
							<div>
								<p className="text-slate-400 text-sm">Passwords must be at least 8 characters long, contain at least 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 symbol.</p>
							</div>
							
							{
								error!=''&&<div className="">
									<span className="text-red-400 text-sm">{error}</span>
								</div>
							}
							{
								message!=''&&<div className="">
									<span className="text-lime-400 text-sm">{message}</span>
								</div>
							}
							<div>
								<button disabled={disabled} className="disabled:opacity-50 shadow-lg bg-indigo-600 text-white rounded-md px-4 py-3 text-center w-full block font-bold text-sm">Reset Password</button>
							</div>
						</div>
						</form>
					</div>
				</div>
				<div className="h-full">
					<div className="bg-cover bg-center rounded-lg h-full" style={{backgroundImage: "url('https://images.unsplash.com/photo-1535295972055-1c762f4483e5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80')"}}>
						&nbsp;
					</div>
				</div>
			</div>
		</div>
		<Toast message={toastMessage} onReset={() => setToastMessage(false)}/>
	</main>
}