import React, {useEffect, useState, useRef} from 'react';
import {
  Link,
  useNavigate,
  useParams
} from "react-router-dom";
import {useAuthRequest} from '../auth.js';
import moment from 'moment';
import { Modal, Title, Label, Toast} from '../dsb.js';
import Layout from '../components/layout';
import {  PlusIcon} from '@heroicons/react/24/solid'

const levels = [
	{label: 'Read', value: 1},
	{label: 'Read / Update', value: 2},
	{label: 'Read / Update / Delete', value: 3}
];

export default function AdminUser() {

	const {authFetch} = useAuthRequest();

	const {id} = useParams();

	const accessLevelRef = useRef();

	const navigate = useNavigate();

	const [user, setUser] = useState(null);

	const [message, setMessage] = useState(false);

	const [confirmText, setConfirmText] = useState('');

	const [deleteModal, setDeleteModal] = useState(false);

	const [permissionType, setPermissionType] = useState('');
	const [accessLevel, setAccessLevel] = useState('');

	const [selectedPermission, setSelectedPermission] = useState(null);
	const [selectedDeletePermission, setSelectedDeletePermission] = useState(null);

	const [newPermissionModal, setNewPermissionModal] = useState(false);

	const loadAdminUser = async() => {
		const response = await authFetch(`/api/admin/users/${id}`);

		const {user, error} = response;

		if (user) {
			setUser(user);
		}

		if (error) {
			navigate('/users');
		}
	}

	const savePermission = async() => {
		const formData = new FormData();

		formData.append('access_level', accessLevelRef.current.value);
		const response = await authFetch(`/api/admin/users/${id}/permissions/${selectedPermission.permission_code}`, 'POST', formData);

		const {message, error} = response;

		if (message) {
			setSelectedPermission(null);
			setMessage(message);
			loadAdminUser();
		}

		if (error) {
			setMessage(error);
		}
	}

	const deletePermission = async() => {
		const response = await authFetch(`/api/admin/users/${id}/permissions/${selectedDeletePermission.permission_code}/delete`, 'POST');

		const {message, error} = response;

		if (message) {
			setSelectedDeletePermission(null);
			setMessage(message);
			loadAdminUser();
		}

		if (error) {
			setMessage(error);
		}
	}

	const addNewPermission = async() => {
		const formData = new FormData();

		formData.append('access_level', accessLevel);
		const response = await authFetch(`/api/admin/users/${id}/permissions/${permissionType}`, 'POST', formData);

		const {message, error} = response;

		if (message) {
			setNewPermissionModal(false);
			setMessage(message);
			loadAdminUser();
		}

		if (error) {
			setMessage(error);
		}
	}

	const deleteUser = async() => {
		const formData = new FormData();

		formData.append('confirm', confirmText);
		const response = await authFetch(`/api/admin/users/${id}/delete`, 'POST', formData);

		const {message, error} = response;

		if (message) {
			setDeleteModal(false);
			setMessage(message);
			setTimeout(() => {
				navigate(`/users`);
			}, 1000);
		}

		if (error) {
			setMessage(error);
			loadAdminUser();
		}
	}

	useEffect(() => {
		loadAdminUser();
	}, []);

	return <Layout current='users'>
		{user!=null&&<div className="max-w-4xl space-y-4">
			<div>
			<Title>Admins / {user.name}</Title>
			<p className="text-slate-200 text-sm">Last logged in {moment(user.last_login_at).fromNow()}</p>
			</div>
			<div className="bg-slate-800 rounded-md">
				<div className="px-4 py-2 flex items-center justify-between border-b border-slate-700">
					<h3 className="font-bold text-white text-xl">Administrative Permissions</h3>
					<button onClick={() => setNewPermissionModal(true)} className="bg-indigo-600 rounded-md font-bold text-white px-4 py-2 text-sm flex items-center space-x-1"><PlusIcon className="w-5 h-5"/><span>New Permission</span></button>
				</div>
				<table className="w-full">
					<thead className="text-left text-slate-200 text-sm border-b border-slate-700">
						<tr>
							<th className='px-4 py-2'>Permission</th>
							<th className='px-3 py-2'>Access</th>
							<th className='px-3 py-2'>Granted</th>
							<th className='text-right px-4 py-2'>Action</th>
						</tr>
					</thead>
					<tbody className="text-slate-400 text-sm divide-y divide-slate-700">
						{
							user.permissions.map(permission => <tr key={`permission-${permission.id}`}>
									<td className='px-4 py-2'><span className="bg-slate-200 text-slate-900 px-3 py-1 rounded-full uppercase font-bold text-xs text-center w-24 inline-block">{permission.permission_code}</span></td>
									<td className='px-3 py-2'>{levels.find(level => level.value==permission.access_level).label}</td>
									<td className="px-3 py-2">{moment(permission.updated_at).fromNow()}</td>
									<td className="px-4 py-2 text-right space-x-2"><button onClick={() => setSelectedPermission(permission)} className="text-cyan-400">Manage</button><button onClick={() => setSelectedDeletePermission(permission)} className="text-rose-400">Delete</button></td>
								</tr>)
						}
					</tbody>
				</table>
			</div>
			<div>
				<button onClick={() => setDeleteModal(true)} className="bg-rose-600 hover:bg-rose-700 text-white inline-block px-6 py-2 font-bold rounded-md text-sm">Delete User</button>
			</div>
			{selectedPermission!=null&&<Modal open={(selectedPermission!=null)} close={() => setSelectedPermission(null)} title={`Manage permissions`} primary={{label: 'Save Changes', action: () => savePermission()}} secondary={{label: 'Close', action: () => setSelectedPermission(null)}}>
				<p className="text-sm text-slate-300 mb-3">You can change <span className="text-white font-bold">{user.name}'s</span> <span className="text-indigo-300 font-bold text-xs uppercase">{selectedPermission.permission_code}</span> access level here.</p>
				<Label>Access Level</Label>
				<select ref={accessLevelRef} defaultValue={selectedPermission.access_level} className="border-slate-700 border px-4 py-3 bg-slate-700 text-slate-200 rounded-md w-full">
					{
						levels.map((level) => <option key={`level-${level.value}`} value={level.value}>{level.label}</option>)
					}
				</select>
			</Modal>}

			{selectedDeletePermission!=null&&<Modal open={(selectedDeletePermission!=null)} close={() => setSelectedDeletePermission(null)} title={`Are you sure?`} primary={{label: 'Confirm Deletion', action: () => deletePermission()}} secondary={{label: 'Close', action: () => setSelectedDeletePermission(null)}}>
				<p className="text-sm text-slate-300 my-2">You are about to delete <span className="text-white font-bold">{user.name}'s</span> <span className="text-indigo-300 font-bold text-xs uppercase">{selectedDeletePermission.permission_code}</span> permissions.</p>
			</Modal>}

			<Modal open={deleteModal} close={() => setDeleteModal(false)} title="Delete this user?" primary={{label: 'Confirm Deletion', action: () => deleteUser(), disabled: (user.name.toUpperCase() != confirmText.toUpperCase())}} secondary={{label: 'Close', action: () => setDeleteModal(false)}}>
				<p className="text-sm text-slate-300 mb-3">You are about to delete <span className="text-white font-bold">{user.name}'s</span> admin account.</p>
				<Label>To confirm, type <span className="text-cyan-400">{user.name.toUpperCase()}</span> in the field below</Label>
				<input placeholder="Enter user's name to confirm..." type="text" className="block w-full bg-white text-black px-4 py-3 rounded-md" onChange={(e) => setConfirmText(e.target.value.toUpperCase())} value={confirmText}/>
			</Modal>
			<Modal open={newPermissionModal} close={() => setNewPermissionModal(false)} title="Add Permission" primary={{label: 'Grant Permission', disabled: (accessLevel==''||permissionType==''), action:() => addNewPermission()}} secondary={{label: 'Close', action: () => setNewPermissionModal(false)}}>
				<div className="space-y-2">
				<div>
					<p className="text-sm text-slate-400">Choose the permission type and access level. If the permission type already exists, its access level will be overwritten.</p>
				</div>
				<div>
					<Label>Permission Type</Label>
					<select value={permissionType} onChange={(e) => setPermissionType(e.target.value)} className="border-slate-700 border px-4 py-3 bg-slate-700 text-slate-200 rounded-md w-full">
						<option value="">-- Select Permission Type</option>
						<option value="stores">Stores</option>
						<option value="orders">Orders</option>
						<option value="users">Users</option>
						<option value="products">Products</option>
						<option value="affiliates">Affiliates</option>
					</select>
				</div>
				<div>
					<Label>Access Level</Label>
					<select value={accessLevel} onChange={(e) => setAccessLevel(e.target.value)} className="border-slate-700 border px-4 py-3 bg-slate-700 text-slate-200 rounded-md w-full">
						<option value="">-- Select Access Level</option>
						<option value="1">Read only</option>
						<option value="2">Read / Update</option>
						<option value="3">Read / Update / Delete</option>
					</select>
				</div>
				</div>
			</Modal>
		</div>}
		<Toast message={message} onReset={() => setMessage(false)}/>


	</Layout>
}